/* eslint-disable react/prop-types */
import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Stack, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import dataTableData from "./dataTableData";
import TableCommon from "components/TableCommon";
//
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { formatDate, formatCurrency } from "utils";
// api
import { YearEndBonusService } from "services/yearEndBonusServices";
import { AuthService } from "services/authService";
import { toast } from "react-toastify";
import { DepartmentService } from "services/departmentService";
function DataTable({
  loading,
  query,
  setQuery,
  entriesPerPage,
  canSearch,
  table,
  paginationData,
  setPaginationData,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SoftPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [isYear, setYear] = useState([]);
  const [isDepartment, setIsDepartment] = useState([]);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.sorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const all = "Tất cả";
  useEffect(() => {
    let currentYear = new Date().getFullYear();
    let arrayLength = 999;
    let startYear = currentYear - 3;
    let yearDifference = 1;
    let arr = [];
    for (let i = 0; i < arrayLength; i++) {
      if (startYear + i * yearDifference <= currentYear) {
        arr.push(startYear + i * yearDifference);
      }
    }
    arr.push(all);
    setYear(arr.reverse());
  }, []);

  const [role, setRole] = useState();
  useEffect(() => {
    (async () => {
      const { data } = await AuthService.getMe();
      setRole(data?.role);
    })();
  }, []);

  const exportToCSV = async () => {
    const { data } = await YearEndBonusService.export(query);
    const listCus = data.map((item, index) => {
      return [
        ++index,
        item?.user?.personalInformation?.officialRank || "--",
        item?.user?.personalInformation?.fullName || "--",
        item?.basicscores?.task1Score || "--",
        item?.basicscores?.task2Score || "--",
        item?.basicscores?.task3Score || "--",
        item?.basicscores?.managerScore || "--",
        item?.basicscores?.rewardScore || 0,
        item?.basicscores?.negativeScore || 0,
        item?.basicscores?.totalScore || "--",
        item?.basicscores?.excellentScore || "--",
        item?.basicscores?.totalExcellentScore || "--",
        item?.basicbonus?.workingMonth || "--",
        item?.basicbonus?.workingMonthCoefficient || "--",
        item?.basicbonus?.groupCoefficient || "--",
        item?.basicbonus?.disciplineCoefficient,
        formatCurrency.format(item?.basicbonus?.basicBonusLevel || 0),
        formatCurrency.format(item?.basicBonus || 0),
        formatCurrency.format(item?.rewardrankings?.bonusRewardLevel || 0),
        item?.rewardrankings ? 1 : 0,
        formatCurrency.format(item?.rewardrankings?.bonustReward || 0),
        formatCurrency.format(item?.specicalPersonalBonus || 0),
        formatCurrency.format(item?.specicalGroupBonus || 0),
        formatCurrency.format(item?.total || 0),
      ];
    });
    const listTitle = [
      [
        "STT",
        "Nhóm",
        "Họ tên VC_NLĐ",
        "NV1",
        "NV2",
        "NV3",
        "Điểm quản lý",
        "Điểm thưởng",
        "Điểm trừ",
        "Điểm tổng A (70%)",
        "Điểm thưởng xuất sắc tối đa (30%)",
        "Điểm tổng B (70% ĐTA + 30% ĐXS)",
        "Thời gian công tác (tháng)",
        "Hệ số tháng làm việc",
        "Hệ số nhóm",
        "Hệ số kỷ luật",
        "Mức thưởng cơ bản",
        "Thưởng cơ bản",
        "Mức thưởng xuất sắc",
        "Được thưởng xuất sắc",
        "Thưởng xuất sắc",
        "Thưởng đặc biệt (cá nhân)",
        "Thưởng đặc biệt (tổ/ban)",
        "Tổng thưởng",
      ],
      ...(listCus || []),
    ];
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.aoa_to_sheet(listTitle);

    const colWidths = [
      { wch: 3 }, //STT
      { wch: 20 }, //Nhóm
      { wch: 30 }, //Họ tên VC_NLĐ
      { wch: 10 }, //NV1
      { wch: 10 }, //NV2
      { wch: 10 }, //NV3
      { wch: 15 }, //Điểm quản lý
      { wch: 15 }, //Điểm thưởng
      { wch: 10 }, //Điểm trừ
      { wch: 15 }, //Điểm tổng A (70%)
      { wch: 35 }, //Điểm thưởng xuất sắc tối đa (30%)
      { wch: 35 }, //Điểm tổng B (70% ĐTA + 30% ĐXS)
      { wch: 30 }, //Thời gian công tác (tháng)
      { wch: 25 }, //Hệ số tháng làm việc
      { wch: 15 }, //Hệ số nhóm
      { wch: 15 }, //Hệ số kỷ luật
      { wch: 20 }, //Mức thưởng cơ bản
      { wch: 15 }, //Thưởng cơ bản
      { wch: 20 }, //Mức thưởng xuất sắc
      { wch: 20 }, //Được thưởng xuất sắc
      { wch: 20 }, //Thưởng xuất sắc
      { wch: 25 }, //Thưởng đặc biệt (cá nhân)
      { wch: 25 }, //Thưởng đặc biệt (tổ/ban)
      { wch: 25 }, //Tổng thưởng
    ];

    ws["!cols"] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataExport = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataExport, `bang-danh-sach-hoat-dong.xlsx`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await DepartmentService.getData();
        setIsDepartment(data);
      } catch (error) {
        toast.error("Lấy dữ liệu Phòng/Ban thất bại!");
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        pb={1}
        style={{ width: "100%" }}
      >
        <Stack spacing={1} direction="row" mb={3}>
          <Link to="/quan-ly-khen-thuong/tong-thuong-cuoi-nam/create">
            <SoftButton variant="gradient" color="dark" size="small">
              Tính Tổng thưởng cuối năm
            </SoftButton>
          </Link>
          {role && role === "admin" && (
            <SoftButton variant="outlined" color="info" size="small" onClick={() => exportToCSV()}>
              Xuất file
            </SoftButton>
          )}
        </Stack>
        <SoftBox lineHeight={1} style={{ width: "100%" }}>
          {/* <Stack spacing={2} direction="row"> */}
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} md={6} lg={2}>
              <SoftBox display="flex" flexDirection="column" width={"100%"}>
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Theo năm
                </SoftTypography>
                <SoftSelect
                  placeholder={new Date().getFullYear()}
                  onChange={(e) => {
                    setQuery((prev) => {
                      if (e.value !== all) {
                        return { ...prev, year: e.value };
                      } else {
                        return { ...prev, year: undefined };
                      }
                    });
                  }}
                  options={isYear?.map((e) => ({ label: e, value: e }))}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Phòng/Ban
                </SoftTypography>
                <SoftSelect
                  onChange={(e) => setQuery((prev) => ({ ...prev, department: e.value }))}
                  options={[
                    { value: "", label: "Tất cả" },
                    ...(isDepartment?.map((item) => ({
                      value: item?._id,
                      label: item?.name,
                    })) || []),
                  ]}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              {canSearch && (
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Tìm kiếm
                  </SoftTypography>
                  <SoftBox width="100%">
                    <SoftInput
                      placeholder="Tìm kiếm..."
                      value={search}
                      onChange={({ currentTarget }) => {
                        setSearch(search);
                        setQuery((prev) => ({ ...prev, searchBy: currentTarget.value }));
                        onSearchChange(currentTarget.value);
                      }}
                    />
                  </SoftBox>
                </SoftBox>
              )}
            </Grid>
          </Grid>
          {/* </Stack> */}
        </SoftBox>
      </SoftBox>

      <TableContainer sx={{ boxShadow: "none" }}>
        <TableCommon
          loading={loading}
          query={query}
          setQuery={setQuery}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          table={table}
          isSorted={isSorted}
          noEndBorder={noEndBorder}
          tableInstance={tableInstance}
        />
      </TableContainer>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  query: PropTypes.any,
  setQuery: PropTypes.any,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
