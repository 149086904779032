/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import { useMemo, useEffect, useState } from "react";
import * as React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import CachedIcon from "@mui/icons-material/Cached";
// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Stack, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import dataTableData from "./dataTableData";
import { DepartmentService } from "services/departmentService";
import { toast } from "react-toastify";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { OfficialsService } from "services/officialsService";
import { formatDate } from "utils";
import TableCommon from "components/TableCommon";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconUpload from "assets/images/cloud.png";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 2,
};

function DataTable({
  loading,
  query,
  setQuery,
  entriesPerPage,
  canSearch,
  table,
  paginationData,
  setPaginationData,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => {
    setPageSize(value);
    setQuery((prev) => ({ ...prev, limit: value || 10 }));
  };

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SoftPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [isDepartment, setIsDepartment] = useState([]);
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    setQuery((prev) => ({ ...prev, searchBy: value || "" }));
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.sorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await DepartmentService.getData();
        setIsDepartment(data);
      } catch (error) {
        toast.error("Lấy dữ liệu Phòng/Ban thất bại!");
      }
    };

    fetchData();
  }, []);

  const exportToCSV = async () => {
    const dataCus = await OfficialsService.exportCSV({
      ...query,
    });

    const listCus = dataCus?.data?.map((item, index) => {
      return [
        // index + 1,
        item?.personalInformation?.fullName ?? "--",
        item?.personalInformation?.officerId ?? "--",
        item?.department?.name ?? "--",
        formatDate(item?.personalInformation?.birthday) ?? "--",
        item?.personalInformation?.homeTown ?? "--",
        item?.personalInformation?.phoneNumber ?? "--", // Removed unnecessary object wrapping
      ];
    });

    const listCustomer = [
      ["Họ và tên", "Mã cán bộ viên chức", "Phòng/Ban", "Năm sinh", "Quê quán", "Số điện thoại"],
      ...(listCus || []),
    ];
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.aoa_to_sheet(listCustomer);

    // Adjust column widths
    const colWidths = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 12 },
      { wch: 20 },
      { wch: 15 },
    ];

    ws["!cols"] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `bang-danh-sach-can-bo-vien-chuc.xlsx`);
  };

  const handleImportFile = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = ["xlsx", "xls", "xlsm", "xlsb", "xml", "csv"];
    const fileExtension = file?.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      toast.error("Chỉ cho phép tải lên các tệp Excel");
      return;
    }

    setFile((prev) => ({ ...prev, name: file?.name }));
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const getNewArr = parsedData.filter((item) => item.length !== 0);
        setFile((prev) => ({ ...prev, data: getNewArr }));
      } catch (error) {
        console.error("Error reading the Excel File:", error);
        toast.error("Đã xảy ra lỗi khi đọc File Excel.");
        setFile();
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleUploadFile = async () => {
    setLoadingImport(true);
    let payload;
    if (file?.data)
      payload = file?.data.slice(1).map((row) => {
        let dateObject;
        if (row[3]) {
          const parts = row[3]?.split("/");
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10);
          const year = parseInt(parts[2], 10);
          dateObject = new Date(year, month - 1, day);
        }
        return {
          fullName: row[0],
          citizenId: row[1],
          officerId: row[1],
          birthday: new Date(dateObject).getTime(),
          homeTown: row[4],
          phoneNumber: row[5],
          gender: "",
          address: "",
          // startTime: new Date().getTime(),
          department: row[2],
          jobPosition: "",
          academicDegree: row[7],
          officialRank: row[8],
          internshipCoefficient: "",
          avatar: "",
          email: row[6],
          isMarker: false,
        };
      });
    try {
      await OfficialsService.importExcel({
        arr: payload,
      });
      toast.success("Nhập File thành công");
      setOpen(false);
      setLoadingImport(false);
    } catch (err) {
      if (err.status !== 500) {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Nhập File thất bại");
      }
      setLoadingImport(false);
    }
  };

  useEffect(() => {
    if (!open) setFile({});
  }, [open]);

  return (
    <>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        pb={1}
      >
        <Stack spacing={1} direction="row" mb={3}>
          <Link to="/quan-ly-can-bo-vien-chuc/can-bo-vien-chuc/create">
            <SoftButton variant="gradient" color="dark" size="small">
              Tạo mới
            </SoftButton>
          </Link>

          <SoftButton
            variant="outlined"
            color="dark"
            size="small"
            onClick={() => handleClickOpen()}
          >
            <label htmlFor="excelFile">Nhập File</label>
          </SoftButton>

          <SoftButton variant="outlined" color="info" size="small" onClick={() => exportToCSV()}>
            Xuất File
          </SoftButton>
        </Stack>
        <SoftBox lineHeight={1} style={{ width: "100%" }}>
          {/* <Stack spacing={2} direction="row"> */}
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Phòng/Ban
                </SoftTypography>
                <SoftSelect
                  onChange={(e) => setQuery((prev) => ({ ...prev, department: e.value }))}
                  options={[
                    { value: "", label: "Tất cả" },
                    ...(isDepartment?.map((item) => ({
                      value: item?._id,
                      label: item?.name,
                    })) || []),
                  ]}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              {canSearch && (
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Tìm kiếm
                  </SoftTypography>
                  <SoftBox width="100%">
                    <SoftInput
                      placeholder="Tìm kiếm"
                      value={search}
                      onChange={({ currentTarget }) => {
                        setSearch(search);
                        onSearchChange(currentTarget.value);
                      }}
                    />
                  </SoftBox>
                </SoftBox>
              )}
            </Grid>
          </Grid>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 500 }}>
              <div className="w-full h-full flex flex-col overflow-hidden">
                <div
                  className="w-full h-full border-dashed border-[1px] rounded flex items-center justify-center p-10 "
                  style={{ borderColor: "rgb(168 162 158)", background: "#f3f4f7" }}
                >
                  <div className=" w-full h-full flex flex-col items-center">
                    <img src={IconUpload} className="w-[100px]" />
                    <span className="text-[#B9C3CD] text-xl font-medium">Nhập File +</span>
                    <span className="text-[#B9C3CD] text-base font-normal">
                      Vui lòng chọn đúng định dạng Tệp Excel
                    </span>
                    <span className="text-[#B9C3CD] text-xs font-normal">
                      ( Không bỏ trống các trường Họ tên, Phòng/Ban, email,... )
                    </span>
                    <a
                      href="https://astraea.sgp1.digitaloceanspaces.com/application/1713867465523-bang-danh-sach-can-bo-vien-chuc%20(3).xlsx"
                      target="_blank"
                      rel="noreferrer"
                      className="cursor-pointer hover:text-[#17c1e8] mt-2 text-[#B9C3CD] text-xs font-normal"
                    >
                      ( Xem File mẫu tại đây !!! )
                    </a>
                    {file?.name && (
                      <div className="flex gap-3 items-center">
                        <span className="text-[#B9C3CD] text-xs text-center font-normal">
                          {file?.name}
                        </span>
                        <label
                          className="cursor-pointer text-[#17c1e8] text-xl font-normal"
                          htmlFor="importFile"
                        >
                          <CachedIcon />
                        </label>
                      </div>
                    )}
                    <div className="w-full flex justify-center mt-5">
                      {loading && (
                        <Stack sx={{ color: "grey.500" }} value={10} spacing={2} direction="row">
                          <CircularProgress color="inherit" />
                        </Stack>
                      )}
                      {!file?.name && !loading ? (
                        <label
                          className="cursor-pointer text-[#17c1e8] text-base font-medium hover:underline"
                          htmlFor="importFile"
                        >
                          Chọn File
                        </label>
                      ) : (
                        <label
                          className="cursor-pointer text-[#17c1e8] text-base font-medium hover:underline"
                          onClick={handleUploadFile}
                        >
                          Tải lên
                        </label>
                      )}
                      <input
                        className="hidden"
                        type="file"
                        id="importFile"
                        onChange={handleImportFile}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </SoftBox>
      </SoftBox>

      <TableContainer sx={{ boxShadow: "none" }}>
        <TableCommon
          loading={loading}
          query={query}
          setQuery={setQuery}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          table={table}
          isSorted={isSorted}
          noEndBorder={noEndBorder}
          tableInstance={tableInstance}
        />
      </TableContainer>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  query: PropTypes.any,
  setQuery: PropTypes.any,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
