/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import avatar from "assets/images/team-2.jpg";
// Data
import DataTable from "./data/index";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ActionCell from "./components/ActionCell";
import { formatDate } from "utils";
import { BookingService } from "services/bookingService";
import SoftBadge from "components/SoftBadge";
import dataTableData from "./data/dataTableData";

function DiemXuatXacCuoiNam() {
  // const [dataTable, setDataTable] = useState({
  //   columns: [
  //     {
  //       Header: "Họ và Tên",
  //       accessor: "name",
  //       width: "10%",
  //       sorted: true,
  //       Cell: ({ value: [name, data] }) => (
  //         <Box display="flex" gap={1} alignItems="center">
  //           <Box width={40} height={40}>
  //             <img
  //               style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 12 }}
  //               src={data.image}
  //               alt={name}
  //             />
  //           </Box>
  //           <Box>{name}</Box>
  //         </Box>
  //       ),
  //     },
  //     {
  //       Header: "Mã cbvc",
  //       accessor: "ma-cbvc",
  //       width: "10%",
  //       Cell: ({ value: [text, time, gioDon] }) => (
  //         <Box display="flex" flexDirection="column" overflow="hidden">
  //           <Box
  //             style={{
  //               maxWidth: 200,
  //               textOverflow: "ellipsis",
  //               whiteSpace: "nowrap",
  //               overflow: "hidden",
  //             }}
  //           >
  //             {text}
  //           </Box>
  //           <Box>
  //             {time} | <strong>Giờ đón</strong>: {gioDon}
  //           </Box>
  //         </Box>
  //       ),
  //     },
  //     {
  //       Header: "Phòng/Ban",
  //       accessor: "bo-mon",
  //       width: "10%",
  //       Cell: ({ value: [text, time] }) => (
  //         <Box display="flex" flexDirection="column" overflow="hidden">
  //           <Box
  //             style={{
  //               maxWidth: 200,
  //               textOverflow: "ellipsis",
  //               whiteSpace: "nowrap",
  //               overflow: "hidden",
  //             }}
  //           >
  //             {text}
  //           </Box>
  //           <Box>{time}</Box>
  //         </Box>
  //       ),
  //     },
  //     { Header: "năm sinh", accessor: "loai-xe", sorted: false },
  //     { Header: "quê quán", accessor: "dich-vu", sorted: false },
  //     { Header: "số điện thoại", accessor: "hinh-thuc-thue-xe", sorted: false },
  //     { Header: "thao tác", accessor: "action", sorted: false },
  //   ],
  //   rows: [],
  // });
  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });

  useEffect(() => {
    // (async () => {
    //   const {
    //     data: { items },
    //   } = await BookingService.getAll({ limit: 100, ...query });
    // setDataTable((prev) => ({
    //   ...prev,
    //   rows: items.map((item) => ({
    //     id: item._id,
    //     name: [
    //       item.name || item?.user?.name,
    //       { image: item?.user?.avatar || avatar, checked: true },
    //     ],
    //     "diem-don": [
    //       item.pickUpPoint,
    //       formatDate(item.dayStart),
    //       formatDate(item.pickUpTime, true),
    //     ],
    //     "diem-den": [item.destination, formatDate(item.dayEnd)],
    //     "loai-xe": item.type,
    //     "dich-vu": item.service || "--",
    //     "hinh-thuc-thue-xe": item.formality || "--",
    //     "trang-thai": (
    //       <SoftBadge
    //         variant="contained"
    //         color={getColorStatus(item.status)}
    //         size="xs"
    //         badgeContent={item.status}
    //         container
    //       />
    //     ),
    //     "ghi-chu": item?.note || "--",
    //     action: <ActionCell item={item} setDataTable={setDataTable} />,
    //   })),
    // }));
    // })();
  }, [query]);

  const getColorStatus = (status) => {
    switch (status) {
      case "Đã huỷ":
        return "error";
      case "Chưa điều phối":
        return "secondary";
      case "Đã điều phối":
        return "info";
      case "Đã hoàn thành":
        return "success";
    }
    // "primary",
    // "secondary",
    // "info",
    // "success",
    // "warning",
    // "error",
    // "light",
    // "dark",
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <DataTable
            // query={query}
            // setQuery={setQuery}
            table={dataTableData}
            // entriesPerPage={{
            //   defaultValue: 7,
            //   entries: [5, 7, 10, 15, 20, 25],
            // }}
            canSearch
          />
          {Object.keys(dataTable.rows)?.length === 0 && !loading && (
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Không có dữ liệu
              </Grid>
            </SoftBox>
          )}

          {Object.keys(dataTable.rows)?.length <= 2 && <SoftBox my={5}></SoftBox>}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default DiemXuatXacCuoiNam;
