import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import dataTableData from "./dataTableData";
import TableCommon from "components/TableCommon";
import { DiscipLineService } from "services/disciplineServices";
import { toast } from "react-toastify";
import { DepartmentService } from "services/departmentService";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function DataTable({
  loading,
  query,
  setQuery,
  entriesPerPage,
  canSearch,
  table,
  paginationData,
  setPaginationData,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    rows,
    pageOptions,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [isDepartment, setIsDepartment] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await DepartmentService.getData();
        setIsDepartment(data);
      } catch (error) {
        toast.error("Lấy dữ liệu Phòng/Ban thất bại!");
      }
    };

    fetchData();
  }, []);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    setQuery((prev) => ({ ...prev, searchBy: value }));
  }, 100);

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const exportToCSV = async () => {
    const dataCus = await DiscipLineService.getAll({
      ...query,
      limit: 99999,
    });

    const listCus = dataCus?.data?.items.map((item, index) => {
      return [
        // index + 1,
        item?.user?.personalInformation?.fullName,
        item?.user?.personalInformation?.officerId,
        item?.user?.department?.name,
        item?.disciplineLevel?.name,
        moment(item.time).format("DD/MM/YYYY"),
      ];
    });

    const listCustomer = [
      ["Họ và tên", "Mã cán bộ viên chức", "Phòng/Ban", "Mức vi phạm", "Ngày vi phạm"],
      ...(listCus || []),
    ];

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.aoa_to_sheet(listCustomer);

    // Adjust column widths
    const colWidths = [{ wch: 25 }, { wch: 20 }, { wch: 25 }, { wch: 20 }, { wch: 12 }];

    ws["!cols"] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `bang-danh-sach-vi-pham.xlsx`);
  };

  return (
    <>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        pb={1}
      >
        <Stack spacing={1} direction="row" mb={3}>
          <Link to="/quan-ly-ky-luat/vi-pham/create">
            <SoftButton variant="gradient" color="dark" size="small">
              Tạo mới
            </SoftButton>
          </Link>
          <SoftButton variant="outlined" color="info" size="small" onClick={exportToCSV}>
            Xuất file
          </SoftButton>
        </Stack>
        <SoftBox lineHeight={1} style={{ width: "100%" }}>
          {/* <Stack spacing={2} direction="row"> */}
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Phòng/Ban
                </SoftTypography>
                <SoftSelect
                  onChange={(e) => setQuery((prev) => ({ ...prev, department: e.value }))}
                  options={[
                    { value: "", label: "Tất cả" },
                    ...(isDepartment?.map((item) => ({
                      value: item?._id,
                      label: item?.name,
                    })) || []),
                  ]}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              {canSearch && (
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Tìm kiếm
                  </SoftTypography>
                  <SoftBox width="20rem">
                    <SoftInput
                      placeholder="Tìm kiếm"
                      value={search}
                      onChange={({ currentTarget }) => {
                        setSearch(search);
                        onSearchChange(currentTarget.value);
                      }}
                    />
                  </SoftBox>
                </SoftBox>
              )}
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>

      <TableCommon
        loading={loading}
        query={query}
        setQuery={setQuery}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        table={table}
        isSorted={isSorted}
        noEndBorder={noEndBorder}
        tableInstance={tableInstance}
      />
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  query: PropTypes.any,
  setQuery: PropTypes.any,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  loading: PropTypes.any,
  paginationData: PropTypes.any,
  setPaginationData: PropTypes.any,
};

export default DataTable;
