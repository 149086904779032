/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";
import { OfficialsService } from "services/officialsService";
import { FundService } from "services/fundServices";
import FormFieldCurrency from "../FormField/currency";

const GROUP_ENUM = {
  NHOM1: "Viên chức quản lý",
  NHOM2: "Ngạch cán bộ viên chức",
  NHOM3A: "Ngạch nghiên cứu viên",
  NHOM3B: "Ngạch kỹ sư",
  NHOM4: "Ngạch chuyên viên",
};

function ChiTietQuyKhenThuong({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    getData,
    onChangeValue,
    setFieldValue,
    role,
    setRole,
  } = formData;
  const { quyThuongNam, nam } = formField;
  const { quyThuongNam: quyThuongNamV, nam: namV } = values;
  const { id } = useParams();
  const [files, setFiles] = useState();
  const [isPayload, setIsPayload] = useState({});
  const [user, setUser] = useState([]);
  const [selected, setSelected] = useState([]);

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");
  const containsConfirm = currentPath.includes("confirm");

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setFieldValue(quyThuongNam.name, getData?.amount || 0);
      setFieldValue(nam.name, getData?.year || 0);
    }
  }, [getData]);

  useEffect(() => {
    setFieldValue("file", files || []);
  }, [files]);

  useEffect(() => {
    const getUser = user.filter((item) => item?._id === selected?.value);
    setRole(getUser?.[0]?.personalInformation?.officialRank);
  }, [selected]);

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormFieldCurrency
                type={quyThuongNam.type}
                label={quyThuongNam.label}
                name={quyThuongNam.name}
                value={quyThuongNamV}
                error={errors.quyThuongNam && touched.quyThuongNam}
                disabled={id && !containsEdit}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={nam.type}
                label={nam.label}
                name={nam.name}
                value={namV}
                error={errors.nam && touched.nam}
                disabled={id && !containsEdit}
              />
            </Grid>
          </Grid>
        </>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietQuyKhenThuong.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietQuyKhenThuong;
