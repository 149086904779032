const form = {
  formId: "new-user-form",
  formField: {
    maNhiemVu: {
      name: "maNhiemVu",
      label: "Mã Nhiệm vụ",
      type: "text",
      placeholder: "Vd: NV-A",
      errorMsg: "Mã nhiệm vụ không được bỏ trống.",
    },
    tenHoatDong: {
      name: "tenHoatDong",
      label: "Tên hoạt động",
      type: "text",
      placeholder: "Phòng/Ban A",
      errorMsg: "Tên hoạt động không được bỏ trống.",
    },
    diemThuongXuatXac: {
      name: "diemThuongXuatXac",
      label: "Điểm thưởng xuất sắc",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Tên hoạt động không được bỏ trống.",
    },
    chiTieu: {
      name: "chiTieu",
      label: "Chỉ tiêu",
      type: "number",
      placeholder: "10",
      errorMsg: "chỉ tiêu không được bỏ trống.",
    },
    diaDiem: {
      name: "diaDiem",
      label: "Địa điểm",
      type: "text",
      placeholder: "10",
      errorMsg: "Địa điểm không được bỏ trống.",
    },
    loaiNhiemVu: {
      name: "loaiNhiemVu",
      label: "Loại Nhiệm Vụ",
      type: "text",
      placeholder: "Phòng/Ban A",
      errorMsg: "Loại nhiệm vụ không được bỏ trống.",
    },

    gioToiDa: {
      name: "gioToiDa",
      label: "Giờ tối đa",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Giờ tối đa không được bỏ trống.",
    },
    nhomHoatDong: {
      name: "nhomHoatDong",
      label: "Tên Nhiệm Vụ",
      type: "text",
      placeholder: "Phòng/Ban A",
      errorMsg: "Nhóm Hoạt động không được bỏ trống.",
    },
    diemThuong: {
      name: "diemThuong",
      label: "Điểm Thưởng",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Điểm thưởng không được bỏ trống.",
    },
    diemTru: {
      name: "diemTru",
      label: "Điểm Trừ",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Điểm trù không được bỏ trống.",
    },
    soLuongThamGia: {
      name: "soLuongThamGia",
      label: "Số Lượng người Tham gia tối đa",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Số Lượng người Tham gia tối đa không được bỏ trống.",
    },
    ngayBatDau: {
      name: "ngayBatDau",
      label: "Ngày Bắt Đầu",
      type: "text",
      placeholder: "Phòng/Ban A",
      errorMsg: "Ngày bắt đầu không được bỏ trống.",
    },
    ngayKetThuc: {
      name: "ngayKetThuc",
      label: "Ngày Kết Thúc",
      type: "text",
      placeholder: "",
      errorMsg: "Ngày kết thúc không được bỏ trống.",
    },
    chuTri: {
      name: "chuTri",
      label: "Trưởng / Chủ trì",
      type: "text",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "Trưởng / Chủ trì không được bỏ trống.",
    },
    pho: {
      name: "pho",
      label: "Phó",
      type: "text",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "",
    },
    hoTro: {
      name: "hoTro",
      label: "Hỗ Trợ",
      type: "text",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "",
    },
    diemChuTri: {
      name: "diemChuTri",
      label: "Điểm Chủ Trì",
      type: "number",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "Điểm chủ trì không được bỏ trống.",
    },
    diemHoTro: {
      name: "diemHoTro",
      label: "Điểm Hỗ Trợ",
      type: "number",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "Điểm hỗ trợ không được bỏ trống.",
    },
    diemPho: {
      name: "diemPho",
      label: "Điểm Phó Chủ Trì",
      type: "number",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "Điểm phó chủ trì không được bỏ trống.",
    },
    diemThamGia: {
      name: "diemThamGia",
      label: "Điểm Tham Gia",
      type: "number",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "Điểm tham gia không được bỏ trống.",
    },
    ghiChu: {
      name: "ghiChu",
      label: "Ghi Chú",
      type: "text",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "Ghi chú không được bỏ trống.",
    },
  },
};

export default form;
