import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import {
  Stack,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import TableCommon from "components/TableCommon";
import { toast } from "react-toastify";
import { BonusServices } from "services/bonusServices";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import SoftSelect from "components/SoftSelect";
import { DepartmentService } from "services/departmentService";

function DataTable({
  loading,
  setDataTable,
  query,
  setQuery,
  entriesPerPage,
  canSearch,
  table,
  paginationData,
  setPaginationData,
  isSorted,
  noEndBorder,
}) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  // const [open3, setOpen3] = useState(false);
  const [year, setYear] = useState(0);
  // const [year2, setYear2] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  // const handleClickOpen3 = () => {
  //   setOpen3(true);
  // };

  // const handleClose3 = () => {
  //   setOpen3(false);
  // };

  const handleDelete = async () => {
    try {
      await BonusServices.deleteRewardRanking(year);
      const {
        data: { items, paginate },
      } = await BonusServices.getAllRewardRanking({
        ...query,
        limit: paginationData.size || 10,
        page: paginationData.page,
      });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => ({
          id: item._id,
          name: [
            item?.user?.personalInformation?.fullName,
            { image: item?.user?.personalInformation?.avatar || avatar, checked: true },
          ],
          "ma-cbvc": item?.user?.personalInformation?.officerId,
          "bo-mon": item?.user?.department?.name,
          "tong-thuong": item?.basicBonus.toLocaleString("vi", {
            style: "currency",
            currency: "VND",
          }),
          nam: item?.year,
          // action: <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
      handleClose();
      handleClose2();
      toast.success("Xoá thành công");
    } catch (error) {
      toast.error("Xoá thất bại");
      console.log("🍕 ~ error:", error);
      handleClose();
      handleClose2();
    }
  };

  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    rows,
    pageOptions,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [isDepartment, setIsDepartment] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await DepartmentService.getData();
        setIsDepartment(data);
      } catch (error) {
        toast.error("Lấy dữ liệu Phòng/Ban thất bại!");
      }
    };

    fetchData();
  }, []);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    setQuery((prev) => ({ ...prev, searchBy: value }));
  }, 100);

  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const exportToCSV = async () => {
    const dataCus = await BonusServices.getDataExportRewardRanking(query);

    const listCus = dataCus?.data.map((item, index) => {
      return [
        // index + 1,
        item?.user?.personalInformation?.fullName,
        item?.user?.personalInformation?.officerId,
        item?.user?.department?.name,
        item?.bonustReward.toLocaleString("vi", {
          style: "currency",
          currency: "VND",
        }),
        item?.year,
      ];
    });

    const listCustomer = [
      ["Họ và tên", "Mã cán bộ viên chức", "Phòng/Ban", "Tổng thưởng", "năm"],
      ...(listCus || []),
    ];

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.aoa_to_sheet(listCustomer);

    // Adjust column widths
    const colWidths = [{ wch: 25 }, { wch: 20 }, { wch: 25 }, { wch: 20 }, { wch: 12 }];

    ws["!cols"] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `bang-danh-sach-thuong-xuat-sac-${query.year}.xlsx`);
  };

  const [select, setSelect1] = useState({ type: "Theo tháng", year: new Date().getFullYear() });

  const [valueYear, setvalueYear] = useState([]);

  // useEffect(() => {
  //   let arr = [];
  //   for (var i = -2; i <= 7; i++) {
  //     const year = new Date().getFullYear() - i;
  //     arr.push({ value: year, label: year });
  //   }
  //   setvalueYear(arr);
  // }, []);

  const all = "Tất cả";

  useEffect(() => {
    let currentYear = new Date().getFullYear();
    let arrayLength = 999;
    let startYear = currentYear - 3;
    let yearDifference = 1;
    let arr = [];
    for (let i = 0; i < arrayLength; i++) {
      if (startYear + i * yearDifference <= currentYear) {
        arr.push(startYear + i * yearDifference);
      }
    }
    arr.push(all);
    setvalueYear(arr.reverse());
  }, []);

  // let selectData = [
  //   { value: "Hoạt động tuyển sinh", label: "Hoạt động tuyển sinh" },
  //   { value: "Hoạt động đào tạo", label: "Hoạt động đào tạo" },
  //   {
  //     value: "Hoạt động hỗ trợ sinh viên",
  //     label: "Hoạt động hỗ trợ sinh viên",
  //   },
  //   {
  //     value: "Hoạt động đảm bảo chất lượng ",
  //     label: "Hoạt động đảm bảo chất lượng",
  //   },
  //   {
  //     value: "Hoạt động nghiên cứu khoa học và chuyển giao công nghệ",
  //     label: "Hoạt động nghiên cứu khoa học",
  //   },
  //   { value: "Hoạt động đối ngoại", label: "Hoạt động đối ngoại" },
  //   { value: "Hoạt động cơ sở vật chất", label: "Hoạt động cơ sở vật chất" },
  //   {
  //     value: "Hoạt động đột xuất",
  //     label: "Hoạt động đột xuất",
  //   },
  // ];

  // function getWindowDimensions() {
  //   const { innerWidth: width, innerHeight: height } = window;
  //   return {
  //     width,
  //     height,
  //   };
  // }

  // const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  // useEffect(() => {
  //   function handleResize() {
  //     setWindowDimensions(getWindowDimensions());
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        pb={1}
        style={{ width: "100%" }}
      >
        <Stack spacing={1} direction="row" mb={3}>
          <Link to="/chuc-nang-khac/thuong-xuat-xac/create">
            <SoftButton variant="gradient" color="dark" size="small">
              Tính tiền thưởng Xuất Sắc
            </SoftButton>
          </Link>
          <SoftButton variant="outlined" color="info" size="small" onClick={exportToCSV}>
            Xuất file
          </SoftButton>
        </Stack>
        <SoftBox lineHeight={1} style={{ width: "100%" }} mb={3}>
          {/* <Stack spacing={2} direction="row"> */}
          <Grid container spacing={2} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} md={6} lg={2}>
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Theo năm
                </SoftTypography>
                <SoftSelect
                  // onChange={(e) => {
                  //   setSelect1((prev) => ({ ...prev, year: e.value }));
                  //   setQuery({ ...query, year: e.value });
                  // }}
                  placeholder={new Date().getFullYear()}
                  onChange={(e) => {
                    setQuery((prev) => {
                      if (e.value !== all) {
                        return { ...prev, year: e.value };
                      } else {
                        return { ...prev, year: undefined };
                      }
                    });
                  }}
                  // value={valueYear.find((item) => item.value === select.year)}
                  options={valueYear?.map((e) => ({ label: e, value: e }))}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                  Phòng/Ban
                </SoftTypography>
                <SoftSelect
                  onChange={(e) => setQuery((prev) => ({ ...prev, department: e.value }))}
                  options={[
                    { value: "", label: "Tất cả" },
                    ...(isDepartment?.map((item) => ({
                      value: item?._id,
                      label: item?.name,
                    })) || []),
                  ]}
                />
              </SoftBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              {canSearch && (
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Tìm kiếm
                  </SoftTypography>
                  <SoftBox width="100%">
                    <SoftInput
                      placeholder="Tìm kiếm..."
                      value={search}
                      onChange={({ currentTarget }) => {
                        setSearch(search);
                        onSearchChange(currentTarget.value);
                      }}
                    />
                  </SoftBox>
                </SoftBox>
              )}
            </Grid>
          </Grid>
          {/* </Stack> */}
        </SoftBox>
        <Stack spacing={1} direction="row">
          <SoftButton variant="outlined" color="error" size="small" onClick={handleClickOpen}>
            Xóa
          </SoftButton>
        </Stack>
      </SoftBox>

      <TableCommon
        loading={loading}
        query={query}
        setQuery={setQuery}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        table={table}
        isSorted={isSorted}
        noEndBorder={noEndBorder}
        tableInstance={tableInstance}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Xóa tính tổng thưởng của năm?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Năm
                </SoftTypography>
              </SoftBox>
              <input
                type="number"
                onChange={(e) => {
                  setYear(e.target.value);
                }}
                style={{ border: "1px solid black", borderRadius: 4 }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={handleClickOpen2} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Xóa tính tổng thưởng của năm {year}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn có chắc chắn muốn xóa?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Hủy</Button>
          <Button onClick={handleDelete} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Dialog
        open={open3}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Xuất excel thưởng xuất sắc của năm?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Năm
                </SoftTypography>
              </SoftBox>
              <input
                type="number"
                onChange={(e) => {
                  setYear2(e.target.value);
                }}
                style={{ border: "1px solid black", borderRadius: 4 }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Hủy</Button>
          <Button onClick={exportToCSV} autoFocus>
            Đồng ý
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  query: PropTypes.any,
  setQuery: PropTypes.any,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  loading: PropTypes.any,
  paginationData: PropTypes.any,
  setPaginationData: PropTypes.any,
  setDataTable: PropTypes.any,
};

export default DataTable;
