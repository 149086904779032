const form = {
  formId: "new-user-form",
  formField: {
    maBoMon: {
      name: "maBoMon",
      label: "Mã Phòng/Ban",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Mã Phòng/Ban không được bỏ trống.",
    },
    tenBoMon: {
      name: "tenBoMon",
      label: "Tên Phòng/Ban",
      type: "text",
      placeholder: "Phòng/Ban A",
      errorMsg: "Tên Phòng/Ban không được bỏ trống.",
    },
  },
};

export default form;
