/* eslint-disable react/prop-types */
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import avatar from "assets/images/team-2.jpg";
// Data
import DataTable from "../vi-pham/data/index";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ActionCell from "../vi-pham/components/ActionCell";
import { formatDate } from "utils";
// import { BookingService } from "services/bookingService";
import SoftBadge from "components/SoftBadge";
import dataTableData from "../vi-pham/data/dataTableData";
import FirstColumn from "examples/FirstColumn";
import { DiscipLineService } from "services/disciplineServices";
import moment from "moment";

function QuanLyViPham() {
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Họ và Tên",
        accessor: "name",
        width: "10%",
        sorted: true,
        Cell: ({ value: [name, data] }) => (
          <Box display="flex" gap={1} alignItems="center">
            <Box width={40} height={40}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 12 }}
                src={data.image}
                alt={name}
              />
            </Box>
            <FirstColumn value={name} />
          </Box>
        ),
      },
      {
        Header: "Mã cbvc",
        accessor: "ma-cbvc",
        width: "10%",
        align: "center",
      },
      {
        Header: "Phòng/Ban",
        accessor: "bo-mon",
      },
      { Header: "Mức vi phạm", accessor: "muc-vi-pham", sorted: false },
      { Header: "Ngày vi phạm", accessor: "ngay-vi-pham", sorted: false, align: "center" },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });

  const [query, setQuery] = useState({ sortBy: "createdAt", sortType: "desc" });
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);
  const [isData, setIsData] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await DiscipLineService.getAll({
        ...query,
        limit: paginationData.size || 10,
        page: paginationData.page,
      });
      setIsData(items);
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => ({
          id: item._id,
          name: [
            item?.user?.personalInformation?.fullName || "--",
            { image: item?.user?.personalInformation?.avatar || "/logo-dhbk.png", checked: true },
          ],
          "ma-cbvc": item?.user?.personalInformation?.officerId || "--",
          "bo-mon": item?.user?.department?.name || "--",
          "muc-vi-pham": item?.disciplineLevel?.name || "--",
          "ngay-vi-pham": moment(item.time).format("DD/MM/YYYY") || "--",
          action: <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  // const getColorStatus = (status) => {
  //   switch (status) {
  //     case "Đã huỷ":
  //       return "error";
  //     case "Chưa điều phối":
  //       return "secondary";
  //     case "Đã điều phối":
  //       return "info";
  //     case "Đã hoàn thành":
  //       return "success";
  //   }
  //   // "primary",
  //   // "secondary",
  //   // "info",
  //   // "success",
  //   // "warning",
  //   // "error",
  //   // "light",
  //   // "dark",
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <DataTable
            loading={loading}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            query={query}
            setQuery={setQuery}
            table={dataTable}
            canSearch
          />
          {Object.keys(isData).length === 0 && !loading && (
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Không có dữ liệu
              </Grid>
            </SoftBox>
          )}
          {Object.keys(isData).length <= 2 && <SoftBox my={5}></SoftBox>}
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default QuanLyViPham;
