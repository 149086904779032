// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import SalesTable from "examples/Tables/SalesTable";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// import Globe from "examples/Globe";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

// Data
import salesTableData from "layouts/dashboards/default/data/salesTableData";
import reportsBarChartData from "layouts/dashboards/default/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboards/default/data/gradientLineChartData";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import SoftBadgeDot from "components/SoftBadgeDot";
import ComplexReportsDoughnutChart from "examples/Charts/DoughnutCharts/ComplexReportsDoughnutChart";
import defaultLineChartData from "layouts/ecommerce/overview/data/defaultLineChartData";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import verticalBarChartData from "layouts/pages/charts/data/verticalBarChartData";
import PieChart from "examples/Charts/PieChart";
import PieChart2 from "examples/Charts/PieChart2";
import SoftDatePicker from "components/SoftDatePicker";
import { useEffect, useState } from "react";
import SoftMonthPicker from "components/SoftMonthPicker";
import { Box, Card } from "@mui/material";
import SoftSelect from "components/SoftSelect";
import HorizontalBarChartCustom from "examples/Charts/BarCharts/HorizontalBarChartCustom";
import DataTable from "./DataTable";
import ActionCell from "./components/ActionCell";
import StackedVerticalBarChart from "examples/Charts/BarCharts/StackedVerticalBarChart";
import { DashboardServices } from "services/dashboardServices";
import { toast } from "react-toastify";

const initPieChartData = {
  labels: ["Được giao", "Chưa hoàn thành", "Đã hoàn thành"],
  datasets: {
    label: "Tổng",
    backgroundColors: ["info", "secondary", "success"],
    data: [0, 0, 0],
  },
};

const initHorizontalBarChartData = {
  labels: [
    "Không hoàn thành (%)  ",
    "Hoàn thành (%)  ",
    "Hoàn thành tốt (%)  ",
    "Hoàn thành xuất sắc (%)  ",
  ],
  datasets: {
    label: "Xếp loại cuối năm",
    backgroundColor: "rgba(255, 244, 207, 1)",
    data: [0, 0, 0, 0],
  },
};

const initVerticalBarChartDataMonth = {
  labels: [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ],
  datasets: [
    {
      label: "Tổng",
      color: "info",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

const initVerticalBarChartDataYear = {
  labels: [],
  datasets: [
    {
      label: "Tổng",
      color: "info",
      data: [],
    },
  ],
};

const initVerticalBarChartDataYear2 = {
  labels: [],
  datasets: [
    {
      label: "Tổng",
      color: "info",
      data: [],
    },
  ],
};

const TYPE_SELECT = [
  { value: "month", label: "Theo tháng" },
  { value: "year", label: "Theo năm" },
];

// const StackedVerticalBarChartData = {
//   labels: [
//     "Tháng 1",
//     "Tháng 2",
//     "Tháng 3",
//     "Tháng 4",
//     "Tháng 5",
//     "Tháng 6",
//     "Tháng 7",
//     "Tháng 8",
//     "Tháng 9",
//     "Tháng 10",
//     "Tháng 11",
//     "Tháng 12",
//   ],
//   datasets: [
//     {
//       label: "Phòng/Ban 1",
//       data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
//       backgroundColor: "rgb(255, 99, 132)",
//     },
//     {
//       label: "Phòng/Ban 2",
//       data: [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65],
//       backgroundColor: "rgb(75, 192, 192)",
//     },
//     {
//       label: "Phòng/Ban 3",
//       data: [15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70],
//       backgroundColor: "rgb(53, 162, 235)",
//     },
//     {
//       label: "Phòng/Ban 4",
//       data: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75],
//       backgroundColor: "rgb(189,206,85)",
//     },
//     {
//       label: "Phòng thí nghiệm 1",
//       data: [25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80],
//       backgroundColor: "rgb(142,124,195)",
//     },
//     {
//       label: "Phòng thí nghiệm 2",
//       data: [30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85],
//       backgroundColor: "rgb(249,203,156)",
//     },
//     {
//       label: "Phòng thí nghiệm 3",
//       data: [35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90],
//       backgroundColor: "rgb(118,165,175)",
//     },
//     {
//       label: "Phòng thí nghiệm 4",
//       data: [40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95],
//       backgroundColor: "rgb(255,217,102)",
//     },
//     {
//       label: "Tổng",
//       data: [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300],
//       backgroundColor: "rgb(244,204,204)",
//     },
//   ],
// };

function Default() {
  // const { values } = breakpoints;
  // const { size } = typography;
  // const { chart, items } = reportsBarChartData;
  const valueYear1 = [];
  const valueYear2 = [];
  const valueYear3 = [];
  const valueYear4 = [];

  for (var i = -2; i <= 7; i++) {
    const year = new Date().getFullYear() - i;
    valueYear1.push({ value: year, label: year });
    valueYear2.push({ value: year, label: year });
    valueYear3.push({ value: year, label: year });
    valueYear4.push({ value: year, label: year });
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [select1, setSelect1] = useState({ year: new Date().getFullYear() });
  const [select2, setSelect2] = useState({
    startYear: new Date().getFullYear(),
    endYear: new Date().getFullYear(),
  });
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Họ Và Tên",
        accessor: "name",
        // sorted: true,
      },
      {
        Header: "Xếp Loại",
        accessor: "xep-loai",
        align: "center",
      },
      {
        Header: "Nhiệm Vụ Chưa Hoàn Thành",
        accessor: "task-cht",
        align: "right",
      },
      {
        Header: "Nhiệm Vụ Hoàn Thành",
        accessor: "task-ht",
        align: "right",
        // sorted: false,
      },
      {
        Header: "Hoạt Động Tham Gia",
        accessor: "actiity",
        align: "right",
        // sorted: false,
      },
      {
        Header: "Tổng Điểm",
        accessor: "tong-diem",
        align: "right",
        // sorted: false,
      },
      {
        Header: "Thời Gian",
        accessor: "time",
        align: "center",
        // sorted: false,
      },
      // {
      //   Header: "thao tác",
      //   accessor: "action",
      //   align: "center",
      //   // sorted: false,
      // },
    ],
    rows: [],
  });
  const [totalHD, setTotalHD] = useState(0);
  const [totalActivity, setTotalActivity] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [totalYear, setTotalYear] = useState(0);
  const [totalTHDGYear, setTotalTHDGYear] = useState(0);
  const [totalTHDGYear2, setTotalTHDGYear2] = useState(0);
  const [pieChartData, setPieChartData] = useState(initPieChartData);
  const [horizontalChartData, setHorizontalChartData] = useState(initHorizontalBarChartData);
  const [verticalBarChartData, setVerticalBarChartData] = useState(initVerticalBarChartDataMonth);
  const [verticalBarChartData2, setVerticalBarChartData2] = useState(initVerticalBarChartDataYear2);
  const [dataTableCBVC, setDataTableCBVC] = useState([]);
  const [selectScoreStatistics, setSelectScoreStatistics] = useState({
    type: "month",
    year: new Date().getFullYear(),
  });

  const [query, setQuery] = useState({
    sortBy: "createdAt",
    sortType: "desc",
    type: "personal",
    year: new Date().getFullYear(),
    // endYear: new Date().getFullYear(),
  });
  const [paginationData, setPaginationData] = useState({ page: 1 });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await DashboardServices.getDataTableRankings({
        ...query,
        limit: paginationData.size || 10,
        page: paginationData.page,
      });

      
      const data2 = await DashboardServices.getDataTableRankings({ ...query, limit: 99999 });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));
      let total = 0;
      data2.data.items.map((item) => (total = total + Number(item?.totalScore)));
      setTotalTHDGYear(total);
      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => {
          return {
            id: item._id,
            name: item?.name,
            "xep-loai": item?.ranking || "--",
            "task-cht":
              new Intl.NumberFormat().format(Number(item?.activityNotCompleted).toFixed(2)) || "--",
            "task-ht":
              new Intl.NumberFormat().format(Number(item?.activityCompleted).toFixed(2)) || "--",
            actiity: new Intl.NumberFormat().format(Number(item?.totalActivity).toFixed(2)) || "--",
            "tong-diem":
              new Intl.NumberFormat().format(Number(item?.totalScore).toFixed(2)) || "--",
            time: item?.year,
            // action: <ActionCell item={item} setDataTable={setDataTable} />,
          };
        }),
      }));
      setLoading(false);
    })();
  }, [query, paginationData.page]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectHD, setSelectHD] = useState({
    startTime: new Date().setHours(0, 0, 0, 0),
    endTime: new Date().setHours(23, 59, 59, 999),
  });

  const handleSetDate = (type, newDate) => {
    const time = new Date(newDate[0]).getTime();
    const time2 = new Date(newDate[0]).setHours(23, 59, 59, 999);
    if (type === "start") {
      if (new Date(newDate[0]).getTime() > selectHD.endTime) {
        toast.error("Ngày bắt đầu phải nhỏ hơn ngày kết thúc");
      } else {
        setStartDate(newDate);
        setSelectHD((prev) => ({ ...prev, startTime: time }));
      }
    }
    if (type === "end") {
      if (new Date(newDate[0]).getTime() < selectHD.startTime) {
        toast.error("Ngày kết thúc phải lớn hơn hơn ngày bắt đầu");
      } else {
        setSelectHD((prev) => ({ ...prev, endTime: time2 }));
        setEndDate(newDate);
      }
    }
  };

  const fetchPieChart = async () => {
    setPieChartData({});
    const { data } = await DashboardServices.getChartPie(selectHD);
    const newData = initPieChartData;
    newData.datasets.data = [0, 0, 0];
    let getData = [0, 0, 0];
    getData[0] = data.notStart;
    getData[1] = data.starting;
    getData[2] = data.end;
    newData.datasets.data = getData;
    setTotalHD(data.notStart + data.starting + data.end);
    setPieChartData(newData);
  };

  const fetchHorizontalChart = async () => {
    setHorizontalChartData({});
    const { data } = await DashboardServices.getChartHorizontal(select1);
    const newData = initHorizontalBarChartData;
    newData.datasets.data = [0, 0, 0, 0];
    let getData = [0, 0, 0, 0];
    for (let i = 0; i < data.rankings.length; i++) {
      if (data.rankings[i]?.ranking === "Không hoàn thành") {
        getData[0] = data.rankings[i].percentage;
      } else if (data.rankings[i].ranking === "Hoàn thành") {
        getData[1] = data.rankings[i].percentage;
      } else if (data.rankings[i].ranking === "Hoàn thành tốt") {
        getData[2] = data.rankings[i].percentage;
      } else {
        getData[3] = data.rankings[i].percentage;
      }
    }
    newData.datasets.data = getData;
    setHorizontalChartData(newData);
    setTotalActivity(data.totalActivity);
  };

  const fetchDataTableCBVC = async () => {
    const { data } = await DashboardServices.getDataTableCBVC({});
    setDataTableCBVC(data.departments);
    setTotalEmployees(data.totalEmployees);
  };

  const fetchChartVertical = async () => {
    setVerticalBarChartData(
      selectScoreStatistics.type === "month"
        ? initVerticalBarChartDataYear
        : initVerticalBarChartDataMonth
    );
    const { data } = await DashboardServices.getChartVertical(selectScoreStatistics);
    if (selectScoreStatistics.type === "month") {
      const newData = initVerticalBarChartDataMonth;
      newData.datasets.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let getData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let total = 0;
      for (let i = 0; i < data.length; i++) {
        getData[i] = data[i].score;
        total = total + data[i].score;
      }
      newData.datasets[0].data = getData;
      setVerticalBarChartData(newData);
      setTotalYear(total);
    } else {
      if (data.length === 1) {
        const newData = initVerticalBarChartDataYear;
        const year = new Date().getFullYear();
        newData.labels = [year - 1, year, year + 1];
        newData.datasets[0].data = [0, 0, 0];
        let getData = [0, 0, 0];
        getData[1] = data[0].score;
        newData.datasets[0].data = getData;
        setVerticalBarChartData(newData);
      } else {
        const newData = initVerticalBarChartDataYear;
        newData.labels = [];
        newData.datasets[0].data = [];
        for (let i = 0; i < data.length; i++) {
          newData.labels.push(data[i].year.toString());
          newData.datasets[0].data.push(+data[i].score);
        }
        setVerticalBarChartData(newData);
      }
    }
  };

  const fetchChartVertical2 = async () => {
    setVerticalBarChartData2({});
    const { data } = await DashboardServices.getChartVertical2(select2);
    if (data.length === 1) {
      const newData = initVerticalBarChartDataYear;
      const year = new Date().getFullYear();
      newData.labels = [year - 1, year, year + 1];
      newData.datasets[0].data = [0, 0, 0];
      let getData = [0, 0, 0];
      getData[1] = data[0].score;
      newData.datasets[0].data = getData;
      setVerticalBarChartData2(newData);
      setTotalTHDGYear2(data[0].score.toFixed(0).toLocaleString());
    } else {
      const newData = initVerticalBarChartDataYear;
      let total = 0;
      newData.labels = [];
      newData.datasets[0].data = [];
      for (let i = 0; i < data.length; i++) {
        newData.labels.push(data[i].year.toString());
        newData.datasets[0].data.push(+data[i].score);
        total = total + data[i].score;
      }
      setVerticalBarChartData2(newData);
      setTotalTHDGYear2(total.toFixed(0).toLocaleString());
    }
  };

  useEffect(() => {
    fetchPieChart();
    fetchDataTableCBVC();
    fetchChartVertical();
    fetchHorizontalChart();
    fetchChartVertical2();
  }, []);

  useEffect(() => {
    fetchPieChart();
  }, [selectHD]);

  useEffect(() => {
    fetchHorizontalChart();
  }, [select1]);

  useEffect(() => {
    fetchChartVertical();
  }, [selectScoreStatistics]);

  useEffect(() => {
    fetchChartVertical2();
  }, [select2]);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <div style={{ height: "100%" }}>
            <PieChart
              title="Hoạt động"
              chart={pieChartData}
              description={
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                  <SoftBox display="flex" ml={-1}>
                    <Grid container spacing={2} style={{ width: "100%", height: "100%" }}>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <SoftBadgeDot color="info" size="sm" badgeContent="Được giao" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <SoftBadgeDot color="secondary" size="sm" badgeContent="Đang thực hiện" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <SoftBadgeDot color="success" size="sm" badgeContent="Đã hoàn thành" />
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: windowDimensions.width < 480 ? "column" : "row",
                      gap: windowDimensions.width < 480 ? 10 : 20,
                      justifyContent: "flex-end",
                      marginTop: "15px",
                    }}
                  >
                    <Grid item xs={12} sm={12} md={0} lg={5} xl={0}>
                      <SoftBox
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        sx={{ gap: "5px" }}
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontSize={14}
                          sx={{ color: "rgba(131, 146, 171, 1)", width: "32%" }}
                          mb={0.5}
                        >
                          Từ ngày
                        </SoftTypography>
                        <SoftDatePicker
                          value={startDate}
                          onChange={(e) => handleSetDate("start", e)}
                        />
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={12} md={0} lg={5} xl={0}>
                      <SoftBox
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        sx={{ gap: "5px" }}
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontSize={14}
                          sx={{ color: "rgba(131, 146, 171, 1)", width: "35%" }}
                          mb={0.5}
                        >
                          Đến ngày
                        </SoftTypography>
                        <SoftDatePicker value={endDate} onChange={(e) => handleSetDate("end", e)} />
                      </SoftBox>
                    </Grid>
                    {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <p style={{ fontSize: 12, color: "#8392AB" }}>Từ tháng</p>
                    <div style={{ maxWidth: "150px" }}>
                      <SoftMonthPicker
                        value={startMonth}
                        onChange={(e) => handleSetDate("start", e)}
                      />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 5 }}
                  >
                    <p style={{ fontSize: 12, color: "#8392AB" }}>Đến tháng</p>
                    <div style={{ maxWidth: "150px" }}>
                      <SoftMonthPicker value={endMonth} onChange={(e) => handleSetDate("end", e)} />
                    </div>
                  </div> */}
                  </div>
                </div>
              }
              totalHD={totalHD}
            />
          </div>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <SoftBox p={2}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ fontSize: 16, fontWeight: "500" }}>Xếp loại cuối năm</p>
                    <div style={{ maxWidth: "180px" }}>
                      <SoftSelect
                        size={"small"}
                        placeholder={"Theo năm"}
                        onChange={(e) => setSelect1((prev) => ({ ...prev, year: e.value }))}
                        value={valueYear1.find((item) => item.value === select1.year)}
                        options={valueYear1}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#344767" }}>
                      Tổng số{" "}
                      <span style={{ fontWeight: "500", fontSize: "20px", color: "#89121A" }}>
                        {totalActivity}
                      </span>{" "}
                      hoạt động
                    </p>
                  </div>
                  <HorizontalBarChartCustom chart={horizontalChartData} />
                </SoftBox>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <SoftBox p={2}>
                  <p style={{ fontSize: 16, fontWeight: "500" }}>Cán bộ viên chức</p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ fontWeight: "500", fontSize: "14px", color: "#344767" }}>
                      Tổng số{" "}
                      <span style={{ fontWeight: "500", fontSize: "20px", color: "#89121A" }}>
                        {totalEmployees}
                      </span>{" "}
                      cán bộ
                    </p>
                  </div>
                  <div
                    style={{
                      height: windowDimensions.width >= 992 ? "200px" : undefined,
                      overflow: "auto",
                      MsOverflowStyle: "none",
                      scrollbarWidth: "none",
                      borderRadius: "16px",
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        backgroundColor: "rgba(239, 247, 249, 1)",
                        boxShadow: "0px 20px 27px 0px rgba(0, 0, 0, 0.05)",
                        borderRadius: "16px",
                      }}
                    >
                      <thead>
                        <tr style={{ width: "100%" }}>
                          <th>
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                margin: "10px",
                                fontSize: 12,
                                fontWeight: "700",
                                color: "rgba(131, 146, 171, 1)",
                              }}
                            >
                              Phòng/Ban
                            </p>
                          </th>
                          <th>
                            <p
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "10px",
                                fontSize: 12,
                                fontWeight: "700",
                                color: "rgba(131, 146, 171, 1)",
                              }}
                            >
                              Số Cán Bộ
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          maxHeight: "150px",
                          overflow: "auto",
                          width: "100%",
                        }}
                      >
                        {dataTableCBVC.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    margin: "10px",
                                    fontSize: 14,
                                    fontWeight: "700",
                                    color: "rgba(52, 71, 103, 1)",
                                  }}
                                >
                                  {item.departmentName}
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: "10px",
                                    fontSize: 14,
                                    fontWeight: "700",
                                    color: "rgba(52, 71, 103, 1)",
                                  }}
                                >
                                  {item.numberOfEmployees}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={3}>
        <Grid item xs={12}>
          <VerticalBarChart
            chartTwo={true}
            title={
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <SoftTypography variant="h6">Thống kê Điểm nhiệm vụ 3</SoftTypography>
                  </Grid>
                  <Grid item xs={4} display={"flex"} justifyContent={"end"}>
                    {selectScoreStatistics.type === "month" && (
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#344767",
                          // width: 230,
                        }}
                      >
                        Thống kê năm {selectScoreStatistics.year} tổng:{" "}
                        <span style={{ fontWeight: "500", fontSize: "20px", color: "#89121A" }}>
                          {new Intl.NumberFormat().format(Number(totalYear).toFixed(2))}
                        </span>{" "}
                        điểm
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={4} display={"flex"} gap={2} justifyContent={"end"}>
                    <Grid container spacing={2}>
                      <Grid item xs={selectScoreStatistics.type === "month" ? 6 : 12}>
                        <SoftSelect
                          onChange={(e) => {
                            if (e.value === "Theo năm") {
                              setSelectScoreStatistics({ type: e.value });
                            } else {
                              setSelectScoreStatistics({
                                type: e.value,
                                year: new Date().getFullYear(),
                              });
                            }
                          }}
                          size={"small"}
                          placeholder={"Theo ..."}
                          value={TYPE_SELECT.find(
                            (item) => item.value === selectScoreStatistics.type
                          )}
                          options={TYPE_SELECT}
                        />
                      </Grid>
                      {selectScoreStatistics.type === "month" && (
                        <Grid item xs={6}>
                          <SoftSelect
                            size={"small"}
                            placeholder={"Theo năm"}
                            onChange={(e) =>
                              setSelectScoreStatistics((prev) => ({ ...prev, year: e.value }))
                            }
                            value={valueYear2.find(
                              (item) => item.value === selectScoreStatistics.year
                            )}
                            options={valueYear2}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: windowDimensions.width > 770 ? "row" : "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 5,
                    }}
                  ></div> */}
                </Grid>
              </div>
            }
            chart={verticalBarChartData}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} lg={12}>
          <Card>
            <DataTable
              loading={loading}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
              query={query}
              setQuery={setQuery}
              table={dataTable}
              canSearch
              totalTHDGYear={new Intl.NumberFormat().format(Number(totalTHDGYear).toFixed(2))}
            />
            {dataTable?.rows.length === 0 && (
              <SoftBox my={7}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  Không có dữ liệu
                </Grid>
              </SoftBox>
            )}
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={3}>
        <Grid item xs={12}>
          <VerticalBarChart
            chartTwo={true}
            title={
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <SoftTypography variant="h6">Thống kê điểm Tổng hợp đánh giá</SoftTypography>
                  </Grid>
                  <Grid item xs={4} display={"flex"} justifyContent={"end"}>
                    {selectScoreStatistics.type === "month" && (
                      <p
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#344767",
                          // width: 230,
                        }}
                      >
                        Thống kê tổng:{" "}
                        <span style={{ fontWeight: "500", fontSize: "20px", color: "#89121A" }}>
                          {new Intl.NumberFormat().format(Number(totalTHDGYear2).toFixed(2))}
                        </span>{" "}
                        điểm
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={4} display={"flex"} gap={2} justifyContent={"end"}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <SoftSelect
                          size={"small"}
                          placeholder={"Từ năm"}
                          onChange={(e) => {
                            if (e.value <= select2.endYear) {
                              setSelect2((prev) => ({ ...prev, startYear: e.value }));
                            } else {
                              toast.error("Năm kết thúc phải nhỏ hơn năm bắt đầu");
                            }
                          }}
                          value={valueYear3.find((item) => item.value === select2.startYear)}
                          options={valueYear3}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SoftSelect
                          size={"small"}
                          placeholder={"Đến năm"}
                          onChange={(e) => {
                            if (select2.startYear <= e.value) {
                              setSelect2((prev) => ({ ...prev, endYear: e.value }));
                            } else {
                              toast.error("Năm bắt đầu phải lớn hơn năm kết thúc");
                            }
                          }}
                          value={valueYear4.find((item) => item.value === select2.endYear)}
                          options={valueYear4}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <div
              style={{
                display: "flex",
                flexDirection: windowDimensions.width > 770 ? "row" : "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
              }}
            ></div> */}
                </Grid>
              </div>
            }
            chart={verticalBarChartData2}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Default;
