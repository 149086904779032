const form = {
  formId: "new-user-form",
  formField: {
    maNhiemVu: {
      name: "maNhiemVu",
      label: "Mã Nhiệm vụ",
      type: "text",
      placeholder: "Vd: NV-A",
      errorMsg: "Mã nhiệm vụ không được bỏ trống.",
    },
    tenNhiemVu: {
      name: "tenNhiemVu",
      label: "Tên Nhiệm Vụ",
      type: "text",
      placeholder: "Phòng/Ban A",
      errorMsg: "Tên nhiệm vụ không được bỏ trống.",
    },
    // thoiGian: {
    //   name: "thoiGian",
    //   label: "Thời Gian / Lần",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Thời gian không được bỏ trống.",
    // },
    // loaiNhiemVu: {
    //   name: "loaiNhiemVu",
    //   label: "Loại Nhiệm Vụ",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Loại nhiệm vụ không được bỏ trống.",
    // },

    // gioToiDa: {
    //   name: "gioToiDa",
    //   label: "Tên Nhiệm Vụ",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Tên nhiệm vụ không được bỏ trống.",
    // },
    // nhomHoatDong: {
    //   name: "nhomHoatDong",
    //   label: "Tên Nhiệm Vụ",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Tên nhiệm vụ không được bỏ trống.",
    // },
    // diemThuong: {
    //   name: "diemThuong",
    //   label: "Điểm Thưởng",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Tên nhiệm vụ không được bỏ trống.",
    // },
    // diemTru: {
    //   name: "diemTru",
    //   label: "Điểm Trừ",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Tên nhiệm vụ không được bỏ trống.",
    // },
    // soLuongThamGia: {
    //   name: "soLuongThamGia",
    //   label: "Số Lượng Tham gia",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Số lượng tham gia không được bỏ trống.",
    // },
    // nhomHoatDong: {
    //   name: "nhomHoatDong",
    //   label: "Kiểu Nhiệm Vụ",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Kiểu nhiệm vụ không được bỏ trống.",
    // },
    // ngayBatDau: {
    //   name: "ngayBatDau",
    //   label: "Ngày Bắt Đầu",
    //   type: "text",
    //   placeholder: "Phòng/Ban A",
    //   errorMsg: "Ngày bắt đầu không được bỏ trống.",
    // },
    // ngayKetThuc: {
    //   name: "ngayKetThuc",
    //   label: "Ngày Kết Thúc",
    //   type: "text",
    //   placeholder: "",
    //   errorMsg: "Ngày kết thúc không được bỏ trống.",
    // },
    ghiChu: {
      name: "ghiChu",
      label: "Ghi Chú",
      type: "text",
      placeholder: "Nhiệm vụ dành cho...",
      errorMsg: "Ghi chú không được bỏ trống.",
    },
  },
};

export default form;
