/* eslint-disable react/prop-types */
import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import SoftInput from "components/SoftInput";
import SoftPagination from "components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { Stack, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SoftButton from "components/SoftButton";
import dataTableData from "./dataTableData";
import { ActivityStatusEnum } from "context";
import TableCommon from "components/TableCommon";
import { DepartmentService } from "services/departmentService";
import { ParticipantService } from "services/participantService";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom";
function DataTable({
  loading,
  query,
  setQuery,
  entriesPerPage,
  canSearch,
  table,
  paginationData,
  setPaginationData,
  isSorted,
  noEndBorder,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const RankingActivityParticipantsListEnum = {
    ALL: "Tất cả",
    EXCELLENCE: "Hoàn thành xuất sắc",
    GOOD: "Hoàn thành tốt",
    COMPLETE: "Hoàn thành",
    NOT_COMPLETED: "Không hoàn thành",
  };

  const ActivityParticipantsListStatusEnum = {
    ALL: "Tất cả",
    UNCONFIRMED: "Chưa xác nhận",
    CONFIRMED: "Đã xác nhận",
    EVALUATED: "Đã đánh giá",
  };

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
  const { id } = useParams();

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SoftPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);
  const [departments, setDepartments] = useState([]);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
    setQuery((prev) => ({ ...prev, searchBy: value }));
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;
    if (isSorted && column.sorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else {
      sortedValue = false;
    }
    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { items },
        } = await DepartmentService.getAllForUser({ limit: 99999999999999 });
        setDepartments([{ name: "Tất cả", _id: "" }, ...items]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const exportToCSV = async () => {
    const dataCus = await ParticipantService.exportCSV({
      activity: id,
      ...query,
    });

    const listCus = dataCus?.data?.map((item, index) => {
      return [
        item?.user?.personalInformation?.fullName || "",
        item?.user?.department?.name || "--",
        item?.user?.personalInformation?.jobPosition || "--",
        item?.rewardPoint || "--",
        item?.minusPoint || "--",
        item?.process || "--",
        item?.ranking || "--",
        item?.files?.length > 0 ? "Đã gửi" : "Chưa gửi",
        item?.status || "",
      ];
    });

    const listCustomer = [
      [
        "Họ và tên",
        "Phòng/Ban",
        "Vị Trí",
        "Điểm thưởng",
        "Điểm trừ",
        "Giờ tham gia",
        "Xếp loại",
        "Minh chứng",
        "Trạng thái duyệt",
      ],
      ...(listCus || []),
    ];
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.aoa_to_sheet(listCustomer);

    const colWidths = [
      { wch: 50 },
      { wch: 50 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
      { wch: 30 },
    ];

    ws["!cols"] = colWidths;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `bang-danh-sach-hoat-dong.xlsx`);
  };

  return (
    <>
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        p={3}
        pb={1}
      >
        <Stack spacing={1} direction="row" mb={3}>
          {/* <Link to="/quan-ly-hoat-dong/hoat-dong/create">
            <SoftButton variant="gradient" color="dark" size="small">
              Tạo mới
            </SoftButton>
          </Link> */}
          <SoftButton variant="outlined" color="info" size="small" onClick={() => exportToCSV()}>
            Xuất file
          </SoftButton>
        </Stack>
        <SoftBox lineHeight={1} style={{ width: "100%" }}>
          <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                {canSearch && (
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                      Tìm kiếm
                    </SoftTypography>
                    <SoftBox width="100%">
                      <SoftInput
                        placeholder="Tìm kiếm"
                        value={search}
                        onChange={({ currentTarget }) => {
                          setSearch(search);
                          onSearchChange(currentTarget.value);
                        }}
                      />
                    </SoftBox>
                  </SoftBox>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <SoftBox display="flex" flexDirection="column" minWidth={350}>
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Phòng/Ban
                  </SoftTypography>
                  <SoftSelect
                    onChange={(e) => setQuery((prev) => ({ ...prev, department: e.value }))}
                    options={departments.map((x) => ({ label: x.name, value: x._id }))}
                  />
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ width: "100%", marginTop: "7px" }}>
              <Grid item xs={12} sm={12} md={12} lg={3}>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Xếp loại
                  </SoftTypography>
                  <SoftSelect
                    onChange={(e) => {
                      setQuery((prev) => {
                        if (e.value !== RankingActivityParticipantsListEnum.ALL) {
                          return { ...prev, ranking: e.value };
                        } else {
                          return;
                        }
                      });
                    }}
                    options={Object.entries(RankingActivityParticipantsListEnum).map(
                      ([key, value]) => ({
                        label: value,
                        value: value,
                      })
                    )}
                  />
                </SoftBox>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3}>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Minh chứng
                  </SoftTypography>
                  <SoftSelect
                    onChange={(e) =>
                      setQuery((prev) => {
                        if (e.value !== "all") {
                          return { ...prev, proof: e.value };
                        } else {
                          return;
                        }
                      })
                    }
                    options={[
                      {
                        label: "Tất cả",
                        value: "all",
                      },
                      {
                        label: "Đã gửi",
                        value: true,
                      },
                      {
                        label: "Chưa gửi",
                        value: false,
                      },
                    ]}
                  />
                </SoftBox>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={3}>
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography component="label" variant="caption" fontWeight="bold" mb={0.5}>
                    Trạng thái duyệt
                  </SoftTypography>
                  <SoftSelect
                    onChange={(e) =>
                      setQuery((prev) => {
                        if (e.value !== ActivityParticipantsListStatusEnum.ALL) {
                          return { ...prev, status: e.value };
                        } else {
                          return;
                        }
                      })
                    }
                    options={Object.entries(ActivityParticipantsListStatusEnum).map(
                      ([key, value]) => ({
                        label: value,
                        value: value,
                      })
                    )}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>

      <TableContainer sx={{ boxShadow: "none" }}>
        <TableCommon
          loading={loading}
          query={query}
          setQuery={setQuery}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          table={table}
          isSorted={isSorted}
          noEndBorder={noEndBorder}
          tableInstance={tableInstance}
        />
      </TableContainer>
    </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  query: PropTypes.any,
  setQuery: PropTypes.any,
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
