const form = {
  formId: "new-user-form",
  formField: {
    canBoVC: {
      name: "canBoVC",
      label: "Cán bộ viên chức",
      type: "text",
      placeholder: "Vd: BM-A",
      errorMsg: "Cán bộ viên chức không được bỏ trống.",
    },
    diemQuanLy: {
      name: "diemQuanLy",
      label: "Giờ quản lý",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Giờ quản lý không được bỏ trống.",
    },
    diemChuyenMon: {
      name: "diemChuyenMon",
      label: "Giờ chuyên môn",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Giờ chuyên môn không được bỏ trống.",
    },
    diemNV1: {
      name: "diemNV1",
      label: "Giờ nhiệm vụ 1",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Giờ nhiệm vụ 1 không được bỏ trống.",
    },
    diemNV2: {
      name: "diemNV2",
      label: "Giờ nhiệm vụ 2",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Giờ nhiệm vụ 2 không được bỏ trống.",
    },
    diemNV3: {
      name: "diemNV3",
      label: "Giờ nhiệm vụ 3",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Giờ nhiệm vụ 3 không được bỏ trống.",
    },
    diemThuong: {
      name: "diemThuong",
      label: "Giờ thưởng",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Giờ thưởng không được bỏ trống.",
    },
    diemTru: {
      name: "diemTru",
      label: "Giờ trừ",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Giờ trừ không được bỏ trống.",
    },
    nam: {
      name: "nam",
      label: "Năm",
      type: "number",
      placeholder: "Phòng/Ban A",
      errorMsg: "Năm không được bỏ trống.",
    },
    ngayBatDau: {
      name: "ngayBatDau",
      label: "Ngày Bắt Đầu",
      type: "text",
      placeholder: "Phòng/Ban A",
      errorMsg: "Ngày bắt đầu không được bỏ trống.",
    },
    ngayKetThuc: {
      name: "ngayKetThuc",
      label: "Ngày Kết Thúc",
      type: "text",
      placeholder: "",
      errorMsg: "Ngày kết thúc không được bỏ trống.",
    },
    ghiChu: {
      name: "ghiChu",
      label: "Ghi chú",
      type: "text",
      placeholder: "Phòng/Ban A",
      errorMsg: "Ghi chú không được bỏ trống.",
    },
  },
};

export default form;
