/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";
import { OfficialsService } from "services/officialsService";

const GROUP_ENUM = {
  NHOM1: "Viên chức quản lý",
  NHOM2: "Ngạch cán bộ viên chức",
  NHOM3A: "Ngạch nghiên cứu viên",
  NHOM3B: "Ngạch kỹ sư",
  NHOM4: "Ngạch chuyên viên",
};

function ChiTietDiemCoBanCuoiNam({ formData }) {
  const {
    formField,
    values,
    errors,
    touched,
    getData,
    onChangeValue,
    setFieldValue,
    role,
    setRole,
  } = formData;
  const {
    canBoVC,
    diemChuyenMon,
    diemNV1,
    diemNV2,
    diemNV3,
    diemThuong,
    diemTru,
    diemQuanLy,
    ghiChu,
    nam,
  } = formField;
  const {
    canBoVC: canBoVCV,
    diemChuyenMon: diemChuyenMonV,
    diemNV1: diemNV1V,
    diemNV2: diemNV2V,
    diemNV3: diemNV3V,
    diemThuong: diemThuongV,
    diemTru: diemTruV,
    diemQuanLy: diemQuanLyV,
    ghiChu: ghiChuV,
    ngayBatDau: ngayBatDauV,
    ngayKetThuc: ngayKetThucV,
    nam: namV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState();
  const [isPayload, setIsPayload] = useState({});
  const [user, setUser] = useState([]);
  const [selected, setSelected] = useState([]);

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");
  const containsConfirm = currentPath.includes("confirm");

  useEffect(() => {
    (async () => {
      const {
        data: { items },
      } = await OfficialsService.getPaginate({
        limit: 9999,
        sortBy: "createdAt",
        sortType: "desc",
      });
      setUser(items);
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      // console.log(getData);
      setSelected({
        value: getData?.user?._id,
        label: getData?.user?.personalInformation?.fullName,
      });
      setFieldValue(canBoVC.name, getData?.user?._id || 0);
      setFieldValue(diemQuanLy.name, getData?.managerScore || 0);
      setFieldValue(diemChuyenMon.name, getData?.technicalScore || 0);
      setFieldValue(diemNV1.name, getData?.task1Score || 0);
      setFieldValue(diemNV2.name, getData?.task2Score || 0);
      setFieldValue(diemNV3.name, getData?.task3Score || 0);
      setFieldValue(diemThuong.name, getData?.rewardScore || 0);
      setFieldValue(diemTru.name, getData?.task3Score || 0);
      setFieldValue(nam.name, getData?.year || 0);
      setFieldValue(ghiChu.name, getData?.note || 0);
      setFieldValue("file", getData?.files || []);
    }
  }, [getData]);

  useEffect(() => {
    setFieldValue("file", files || []);
  }, [files]);

  useEffect(() => {
    const getUser = user.filter((item) => item?._id === selected?.value);
    setRole(getUser?.[0]?.personalInformation?.officialRank);
  }, [selected]);

  // if (id && containsEdit && (!getData || Object.keys(getData).length === 0)) {
  //   return <>Loading...</>;
  // }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h5" fontWeight="bold">
          {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Grid item xs={12} sm={12}>
              <Grid item xs={12} sm={12}>
                {id && !containsEdit ? (
                  <FormField
                    type={canBoVC.type}
                    label={canBoVC.label}
                    name={canBoVC.name}
                    value={getData?.user?.personalInformation?.fullName}
                    error={errors.canBoVC && touched.canBoVC}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        mb={0.9}
                        mt={1.7}
                      >
                        {canBoVC.label}
                      </SoftTypography>
                      <SoftSelect
                        closeMenuOnSelect={true}
                        // isLoading={loading}
                        // onMenuOpen={handleFetchDrivers}
                        onChange={(e) => {
                          setSelected((prev) => ({
                            value: e.value,
                            label: e.label,
                          }));
                          setFieldValue(canBoVC.name, e.value);
                        }}
                        value={selected}
                        options={user?.map((e) => ({
                          value: e._id,
                          label: e?.personalInformation?.fullName,
                        }))}
                      />
                    </SoftBox>
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        <ErrorMessage name={canBoVC.name} />
                      </SoftTypography>
                    </SoftBox>
                  </>
                )}
              </Grid>
              {/* <SoftBox mt={0.75}>
                <SoftTypography component="div" variant="caption" color="error">
                  <ErrorMessage name={canBoVC.name} />
                </SoftTypography>
              </SoftBox> */}
            </Grid>
          </Grid>
        </Grid>

        {role || containsEdit || id ? (
          <>
            <Grid container spacing={3}>
              {role !== GROUP_ENUM.NHOM2 &&
              role !== GROUP_ENUM.NHOM3B &&
              role !== GROUP_ENUM.NHOM3A &&
              role !== GROUP_ENUM.NHOM4 ? (
                <Grid item xs={12} sm={role !== GROUP_ENUM.NHOM1 ? 6 : containsEdit ? 12 : 12}>
                  <FormField
                    type={diemQuanLy.type}
                    label={diemQuanLy.label}
                    name={diemQuanLy.name}
                    value={diemQuanLyV}
                    error={errors.diemQuanLy && touched.diemQuanLy}
                    disabled={id && !containsEdit}
                  />
                </Grid>
              ) : (
                <></>
              )}
              {role === GROUP_ENUM.NHOM3B ||
              role === GROUP_ENUM.NHOM4 ||
              (role !== GROUP_ENUM.NHOM3A &&
                role !== GROUP_ENUM.NHOM1 &&
                role !== GROUP_ENUM.NHOM2) ? (
                <Grid item xs={12} sm={role !== GROUP_ENUM.NHOM1 ? 6 : containsEdit ? 6 : 12}>
                  <FormField
                    type={diemChuyenMon.type}
                    label={diemChuyenMon.label}
                    name={diemChuyenMon.name}
                    value={diemChuyenMonV}
                    error={errors.diemChuyenMon && touched.diemChuyenMon}
                    disabled={id && !containsEdit}
                  />
                </Grid>
              ) : role === GROUP_ENUM.NHOM1 ? (
                <></>
              ) : (
                <Grid item xs={12} sm={5}></Grid>
              )}
            </Grid>

            <Grid container spacing={3}>
              {role !== GROUP_ENUM.NHOM3B &&
              role !== GROUP_ENUM.NHOM3A &&
              role !== GROUP_ENUM.NHOM4 ? (
                <Grid item xs={12} sm={6}>
                  <FormField
                    type={diemNV1.type}
                    label={diemNV1.label}
                    name={diemNV1.name}
                    value={diemNV1V}
                    error={errors.diemNV1 && touched.diemNV1}
                    disabled={id && !containsEdit}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid
                item
                xs={12}
                sm={role !== GROUP_ENUM.NHOM3B && role !== GROUP_ENUM.NHOM3A ? 6 : 12}
              >
                {role !== GROUP_ENUM.NHOM3B && role !== GROUP_ENUM.NHOM4 ? (
                  <FormField
                    type={diemNV2.type}
                    label={diemNV2.label}
                    name={diemNV2.name}
                    value={diemNV2V}
                    error={errors.diemNV2 && touched.diemNV2}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {containsEdit && (
                <Grid
                  item
                  xs={12}
                  sm={role !== GROUP_ENUM.NHOM1 ? 12 : role !== GROUP_ENUM.NHOM2 ? 12 : 12}
                >
                  <FormField
                    type={diemNV3.type}
                    label={diemNV3.label}
                    name={diemNV3.name}
                    value={diemNV3V}
                    error={errors.diemNV3 && touched.diemNV3}
                    disabled={id}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <FormField
                  type={diemThuong.type}
                  label={diemThuong.label}
                  name={diemThuong.name}
                  value={diemThuongV}
                  error={errors.diemThuong && touched.diemThuong}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={diemTru.type}
                  label={diemTru.label}
                  name={diemTru.name}
                  value={diemTruV}
                  error={errors.diemTru && touched.diemTru}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>
            {/* <Grid container spacing={3}></Grid> */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={nam.type}
                  label={nam.label}
                  name={nam.name}
                  value={namV}
                  error={errors.nam && touched.nam}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={ghiChu.type}
                  label={ghiChu.label}
                  name={ghiChu.name}
                  value={ghiChuV}
                  error={errors.ghiChu && touched.ghiChu}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FileAttachment
                  id={id}
                  containsEdit={containsEdit}
                  containsCreate={containsCreate}
                  containsConfirm={containsConfirm}
                  file={getData?.files || []}
                  setFile={setFiles}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Vui lòng chọn cán bộ
              </Grid>
            </SoftBox>
            <SoftBox my={30}></SoftBox>
          </>
        )}
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for UserInfo
ChiTietDiemCoBanCuoiNam.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietDiemCoBanCuoiNam;
