/* eslint-disable react/prop-types */
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";

// NewUser page components
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import FormField from "../FormField";
import SoftDatePicker from "components/SoftDatePicker";
import { formatDate } from "utils";
import avatar from "assets/images/avatar.jpg";
import FileAttachment from "components/AttachmentFile";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import dataTableData from "../../data/dataTableData";
import DataTable from "../../data/index";
import { ActivityGroupServices } from "services/activityGroupServices";
import { OfficialsService } from "services/officialsService";
import { truncateText } from "utils";
import ActionCell from "../ActionCell";
import FirstColumn from "examples/FirstColumn";
import { ParticipantService } from "services/participantService";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ChiTietHoatDong({ formData }) {
  const ActivityParticipantsListStatusEnum = {
    UNCONFIRMED: "Chưa xác nhận",
    CONFIRMED: "Đã xác nhận",
    EVALUATED: "Đã đánh giá",
  };
  const { formField, values, errors, touched, getData, onChangeValue, setFieldValue } = formData;
  const {
    diemThuong,
    diemTru,
    ghiChu,
    gioToiDa,
    nhomHoatDong,
    loaiNhiemVu,
    maNhiemVu,
    ngayBatDau,
    ngayKetThuc,
    soLuongThamGia,
    tenHoatDong,
    chuTri,
    hoTro,
    pho,
    chiTieu,
    diaDiem,
    diemThuongToiDa,
    hanGuiMinhChung,
    loaiHD,
  } = formField;
  const {
    diemThuong: diemThuongV,
    gioToiDa: gioToiDaV,
    loaiNhiemVu: loaiNhiemVuV,
    ngayBatDau: ngayBatDauV,
    ngayKetThuc: ngayKetThucV,
    nhomHoatDong: nhomHoatDongV,
    soLuongThamGia: soLuongThamGiaV,
    tenHoatDong: tenHoatDongV,
    ghiChu: ghiChuV,
    diemTru: diemTruV,
    chuTri: chuTriV,
    diemChuTri: diemChuTriV,
    diemHoTro: diemHoTroV,
    diemPho: diemPhoV,
    diemThamGia: diemThamGiaV,
    hoTro: hoTroV,
    pho: phoV,
    chiTieu: chiTieuV,
    diaDiem: diaDiemV,
    diemThuongToiDa: diemThuongToiDaV,
    hanGuiMinhChung: hanGuiMinhChungV,
    loaiHD: loaiHDV,
  } = values;
  const { id } = useParams();
  const [files, setFiles] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [deadLineDate, setDeadLineDate] = useState();
  const [loading, setLoading] = useState(false);
  const [isData, setIsData] = useState([]);
  const [isPayload, setIsPayload] = useState({
    chuTri: [],
    pho: [],
    hoTro: [],
  });
  const [value, setValue] = useState(0);
  const [activityGroup, setActivityGroup] = useState([]);
  const [officals, setOfficals] = useState([]);
  const [host, setHost] = useState([]);
  const [deputy, setDeputy] = useState([]);
  const [supporter, setSupporter] = useState([]);
  const [selecteActivityGroup, setSelecteActivityGroup] = useState();
  const [activityType, setActivityType] = useState();
  const [dataTable, setDataTable] = useState({
    columns: [
      {
        Header: "Họ và Tên",
        accessor: "name",
        width: "10%",
        sorted: false,
        Cell: ({ value }) => {
          const { name, avatar } = value;

          return (
            <Box display="flex" gap={1} alignItems="center">
              <Box width={40} height={40}>
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: 12 }}
                  src={avatar}
                  alt={name}
                />
              </Box>
              {/* <FirstColumn value={name} /> */}
              {name}
            </Box>
          );
        },
      },
      {
        Header: "Phòng/Ban",
        accessor: "bo-mon",
      },
      { Header: "Vị trí", accessor: "vi-tri", sorted: false, align: "center" },
      { Header: "điểm thưởng", accessor: "diem-thuong", sorted: false, align: "right" },
      { Header: "điểm trừ", accessor: "diem-tru", sorted: false, align: "right" },
      { Header: "Giờ tham gia", accessor: "gio-tham-gia", sorted: false, align: "right" },
      { Header: "xếp loại", accessor: "xep-loai", sorted: false, align: "center" },
      {
        Header: "Minh chứng",
        accessor: "mc",
        sorted: false,
        align: "center",
        Cell: ({ value }) => {
          return (
            <span
              style={{
                color: value === "Đã gửi" ? "#17C1E8" : "red",
              }}
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: "Trạng thái duyệt",
        accessor: "status",
        sorted: false,
        align: "center",
        Cell: ({ value }) => {
          return (
            <span
              style={{
                color: value !== ActivityParticipantsListStatusEnum.UNCONFIRMED ? "#17C1E8" : "red",
              }}
            >
              {value}
            </span>
          );
        },
      },
      { Header: "thao tác", accessor: "action", sorted: false, align: "center" },
    ],
    rows: [],
  });
  const [query, setQuery] = useState({
    sortBy: "createdAt",
    sortType: "desc",
  });

  const TypeActivityEnum = [
    "Hoạt động khuyến khích",
    "Hoạt động nhóm",
    "Hoạt động bắt buộc",
    "Hoạt động khác",
  ];

  const [paginationData, setPaginationData] = useState({ page: 1 });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSetDob = (newDate, type) => {
    const time = new Date(newDate[0]).getTime();
    if (type === ngayBatDau.name) {
      setStartDate(time);
      setFieldValue(ngayBatDau.name, time);
    } else if (type === ngayKetThuc.name) {
      setEndDate(time);
      setFieldValue(ngayKetThuc.name, time);
    } else if (type === hanGuiMinhChung.name) {
      setDeadLineDate(time);
      setFieldValue(hanGuiMinhChung.name, time);
    }
  };

  const currentPath = window.location.pathname;
  const containsEdit = currentPath.includes("edit");
  const containsCreate = currentPath.includes("create");
  const containsConfirm = currentPath.includes("confirm");

  useEffect(() => {
    const getActivityGroup = async () => {
      const currentYear = new Date().getFullYear();
      const { data } = await ActivityGroupServices.getAll({
        limit: 100,
        year: currentYear,
        sortBy: "createdAt",
        sortType: "desc",
      });
      setActivityGroup(data.items);
    };
    getActivityGroup();
  }, []);

  useEffect(() => {
    const getOfficials = async () => {
      const { data } = await OfficialsService.getAll();
      setOfficals(data);
    };
    getOfficials();
  }, []);

  useEffect(() => {
    if (Object.keys(isPayload).length > 0) {
      setFieldValue(chuTri.name, isPayload?.chuTri);
      setFieldValue(pho.name, isPayload?.pho);
      setFieldValue(hoTro.name, isPayload?.hoTro);
      if (isPayload?.nhomHoatDong) {
        setFieldValue(nhomHoatDong.name, isPayload?.nhomHoatDong);
      }
      if (isPayload?.loaiHD) {
        setFieldValue(loaiHD.name, isPayload?.loaiHD);
      }
    }
  }, [isPayload]);

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      setStartDate(new Date(Number(getData?.startTime)));
      setEndDate(new Date(Number(getData?.endTime)));
      setDeadLineDate(new Date(Number(getData?.deadLineToPost)));
      setFieldValue(tenHoatDong.name, getData?.name || "--");
      setFieldValue(chiTieu.name, getData?.targets || 0);
      setFieldValue(diaDiem.name, getData?.places || "--");
      setFieldValue(diemThuong.name, getData?.rewardPoint);
      setFieldValue(diemTru.name, getData?.deductionPoint);
      setFieldValue(diemThuongToiDa.name, getData?.maxExcellentPoint || 0);
      setFieldValue("file", getData?.files || []);

      setFieldValue(soLuongThamGia.name, getData?.numberOfParticipants || 0);
      setFieldValue(ngayBatDau.name, getData?.startTime || "--");
      setFieldValue(ngayKetThuc.name, getData?.endTime || "--");
      setFieldValue(hanGuiMinhChung.name, getData?.deadLineToPost || "--");
      setFieldValue(loaiHD.name, getData?.type || "--");

      setFieldValue(ghiChu.name, getData?.note || "--");
      setFieldValue(gioToiDa.name, getData?.maxHours || 0);

      setIsPayload((prevState) => ({
        ...prevState,
        nhomHoatDong: getData?.activityGroup?._id,
      }));

      setIsPayload((prevState) => ({
        ...prevState,
        gioToiDa: getData?.maxHours,
      }));

      const selectedHostValues = getData.host.map((option) => option.value);
      setIsPayload((prevState) => ({
        ...prevState,
        chuTri: selectedHostValues,
      }));
    }

    setSelecteActivityGroup({
      value: getData?.activityGroup?._id,
      label: getData?.activityGroup?.name,
    });
    setActivityType({
      value: getData?.type,
      label: getData?.type,
    });

    ///
    if (getData.host && getData.host.length > 0) {
      const options = getData.host.map((items) => ({
        label: items.personalInformation?.fullName || "--",
        value: items._id || "--",
      }));
      setHost(options);
      const selectedHostValues = getData.host.map((option) => option._id);
      setIsPayload((prevState) => ({
        ...prevState,
        chuTri: selectedHostValues,
      }));
    }
    if (getData.deputy && getData.deputy.length > 0) {
      const options = getData.deputy.map((items) => ({
        label: items.personalInformation?.fullName || "--",
        value: items._id || "--",
      }));
      setDeputy(options);
      const selectedHostValues = getData.deputy.map((option) => option._id);
      setIsPayload((prevState) => ({
        ...prevState,
        pho: selectedHostValues,
      }));
    }
    if (getData.supporter && getData.supporter.length > 0) {
      const options = getData.supporter.map((items) => ({
        label: items.personalInformation?.fullName || "--",
        value: items._id || "--",
      }));
      setSupporter(options);
      const selectedHostValues = getData.supporter.map((option) => option._id);
      setIsPayload((prevState) => ({
        ...prevState,
        hoTro: selectedHostValues,
      }));
    }
  }, [getData]);

  useEffect(() => {
    setFieldValue("file", files || []);
  }, [files]);

  useEffect(() => {
    const getParticipant = async () => {
      setLoading(true);
      const {
        data: { items, paginate },
      } = await ParticipantService.getAll({
        activity: id,
        ...query,
        limit: paginationData.size || 10,
        page: paginationData.page,
      });
      setPaginationData((prev) => ({ ...prev, count: paginate.count, size: paginate.size }));

      setDataTable((prev) => ({
        ...prev,
        rows: items.map((item) => ({
          id: item._id,
          name:
            {
              name: item?.user?.personalInformation?.fullName,
              avatar: item?.user?.personalInformation?.avatar || "/logo-dhbk.png",
            } || "",
          "bo-mon": item?.user?.department?.name || "--",
          "vi-tri": item?.user?.personalInformation?.jobPosition || "--",
          "diem-thuong": item?.rewardPoint || "--",
          "diem-tru": item?.minusPoint || "--",
          "gio-tham-gia": item?.process || "--",
          "xep-loai": item?.ranking || "--",
          mc: item?.files?.length > 0 ? "Đã gửi" : "Chưa gửi",
          status: item?.status || "",
          action: !item?.title && <ActionCell item={item} setDataTable={setDataTable} />,
        })),
      }));
      setLoading(false);
    };
    getParticipant();
  }, [query, paginationData.page]);

  const handleMultiSelect = (selectedOptions, type) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    if (type === chuTri.name) {
      setHost(selectedOptions);
      setIsPayload((prevState) => ({
        ...prevState,
        chuTri: selectedValues,
      }));
    } else if (type === pho.name) {
      setDeputy(selectedOptions);
      setIsPayload((prevState) => ({
        ...prevState,
        pho: selectedValues,
      }));
    } else {
      setSupporter(selectedOptions);
      setIsPayload((prevState) => ({
        ...prevState,
        hoTro: selectedValues,
      }));
    }
  };

  // const handleSelectActivityGroup = (selectedOptions) => {
  //   const selectedValues = selectedOptions.map((option) => option.value);
  //   setSelecteActivityGroup({ value: e.values, label: e.label });
  //   setIsPayload((prevState) => ({
  //     ...prevState,
  //     nhomHoatDong: selectedValues,
  //   }));
  // };

  return (
    <div>
      {(containsEdit || containsCreate) && (
        <SoftBox lineHeight={0}>
          <SoftTypography variant="h5" fontWeight="bold">
            {id && containsEdit ? "Cập nhật" : id ? "Chi tiết" : "Tạo mới"}
          </SoftTypography>
        </SoftBox>
      )}

      <Box sx={{ width: "100%" }}>
        {id && !containsEdit && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Chi tiết" {...a11yProps(0)} />
              <Tab label="Danh sách tham gia" {...a11yProps(1)} />
            </Tabs>
          </Box>
        )}
        <CustomTabPanel value={value} index={0}>
          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={tenHoatDong.type}
                  label={tenHoatDong.label}
                  name={tenHoatDong.name}
                  value={tenHoatDongV}
                  error={errors.tenHoatDong && touched.tenHoatDong}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={chiTieu.type}
                  label={chiTieu.label}
                  name={chiTieu.name}
                  value={chiTieuV}
                  error={errors.chiTieu && touched.chiTieu}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={diaDiem.type}
                  label={diaDiem.label}
                  name={diaDiem.name}
                  value={diaDiemV}
                  error={errors.diaDiem && touched.diaDiem}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormField
                  type={diemThuong.type}
                  label={diemThuong.label}
                  name={diemThuong.name}
                  value={diemThuongV}
                  error={errors.diemThuong && touched.diemThuong}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type={diemTru.type}
                  label={diemTru.label}
                  name={diemTru.name}
                  value={diemTruV}
                  error={errors.diemTru && touched.diemTru}
                  disabled={id && !containsEdit}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormField
                  type={diemThuongToiDa.type}
                  label={diemThuongToiDa.label}
                  name={diemThuongToiDa.name}
                  value={diemThuongToiDaV}
                  error={errors.diemThuongToiDa && touched.diemThuongToiDa}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>

            {/* <Grid container spacing={3}></Grid> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {id && !containsEdit ? (
                  <FormField
                    type={chuTri.type}
                    label={chuTri.label}
                    name={chuTri.name}
                    value={
                      getData?.host?.length >= 4
                        ? truncateText(
                            getData?.host?.map((e) => e?.personalInformation?.fullName).toString(),
                            40
                          )
                        : getData?.host?.map((e) => e?.personalInformation?.fullName)
                    }
                    error={errors.chuTri && touched.chuTri}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        mb={0.9}
                        mt={1.7}
                      >
                        {chuTri?.label}
                      </SoftTypography>
                      <SoftSelect
                        isMulti
                        // isLoading={loading}
                        // onMenuOpen={handleFetchDrivers}
                        onChange={(e) => handleMultiSelect(e, chuTri.name)}
                        value={host}
                        options={officals?.map((items) => ({
                          label: items?.personalInformation?.fullName,
                          value: items?._id,
                        }))}
                      />
                    </SoftBox>
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        <ErrorMessage name={chuTri.name} />
                      </SoftTypography>
                    </SoftBox>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {id && !containsEdit ? (
                  <FormField
                    type={pho.type}
                    label={pho.label}
                    name={pho.name}
                    value={
                      getData?.deputy?.length >= 4
                        ? truncateText(
                            getData?.deputy
                              ?.map((e) => e?.personalInformation?.fullName)
                              .toString(),
                            40
                          )
                        : getData?.deputy?.map((e) => e?.personalInformation?.fullName)
                    }
                    error={errors.pho && touched.pho}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        mb={0.9}
                        mt={1.7}
                      >
                        {pho?.label}
                      </SoftTypography>
                      <SoftSelect
                        isMulti
                        // isLoading={loading}
                        // onMenuOpen={handleFetchDrivers}
                        value={deputy}
                        onChange={(e) => handleMultiSelect(e, pho.name)}
                        options={officals?.map((items) => ({
                          label: items?.personalInformation?.fullName,
                          value: items?._id,
                        }))}
                      />
                    </SoftBox>
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        <ErrorMessage name={pho.name} />
                      </SoftTypography>
                    </SoftBox>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {id && !containsEdit ? (
                  <FormField
                    type={hoTro.type}
                    label={hoTro.label}
                    name={hoTro.name}
                    value={
                      getData?.supporter?.length >= 4
                        ? truncateText(
                            getData?.supporter
                              ?.map((e) => e?.personalInformation?.fullName)
                              .toString(),
                            40
                          )
                        : getData?.supporter?.map((e) => e?.personalInformation?.fullName)
                    }
                    error={errors.hoTro && touched.hoTro}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        mb={0.9}
                        mt={1.7}
                      >
                        {hoTro?.label}
                      </SoftTypography>
                      <SoftSelect
                        isMulti
                        // isLoading={loading}
                        // onMenuOpen={handleFetchDrivers}
                        value={supporter}
                        onChange={(e) => handleMultiSelect(e, hoTro.name)}
                        options={officals?.map((items) => ({
                          label: items?.personalInformation?.fullName,
                          value: items?._id,
                        }))}
                      />
                    </SoftBox>
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        <ErrorMessage name={hoTro.name} />
                      </SoftTypography>
                    </SoftBox>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={gioToiDa.type}
                  label={gioToiDa.label}
                  name={gioToiDa.name}
                  value={gioToiDaV}
                  error={errors.gioToiDa && touched.gioToiDa}
                  disabled={id && !containsEdit}
                />
                <SoftBox mt={0.75}>
                  <SoftTypography component="div" variant="caption" color="error">
                    <ErrorMessage name={gioToiDa.name} />
                  </SoftTypography>
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={id && !containsEdit ? 6 : 12}>
                <FormField
                  type={soLuongThamGia.type}
                  label={soLuongThamGia.label}
                  name={soLuongThamGia.name}
                  value={soLuongThamGiaV}
                  error={errors.soLuongThamGia && touched.soLuongThamGia}
                  disabled={id && !containsEdit}
                />
              </Grid>
              {id && !containsEdit && (
                <Grid item xs={12} sm={6}>
                  <FormField
                    type={soLuongThamGia.type}
                    label={"Số lượng người đã tham gia"}
                    name={soLuongThamGia.name}
                    value={getData?.activityparticipantslists?.length || 0}
                    error={errors.soLuongThamGia && touched.soLuongThamGia}
                    disabled={id && !containsEdit}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                {id && !containsEdit ? (
                  <FormField
                    type={nhomHoatDong.type}
                    label={nhomHoatDong.label}
                    name={nhomHoatDong.name}
                    value={getData?.activityGroup?.name}
                    error={errors.nhomHoatDong && touched.nhomHoatDong}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        mb={0.9}
                        mt={1.7}
                      >
                        Nhóm Hoạt Động
                      </SoftTypography>
                      <SoftSelect
                        // isLoading={loading}
                        // onMenuOpen={handleFetchDrivers}
                        value={selecteActivityGroup}
                        // onChange={handleSelectActivityGroup}
                        onChange={(e) => {
                          setIsPayload((prev) => ({ ...prev, nhomHoatDong: e.value ?? "" }));
                          setSelecteActivityGroup({ value: e.values, label: e.label });
                        }}
                        options={activityGroup?.map((items) => ({
                          label: items?.name,
                          value: items?._id,
                        }))}
                      />
                    </SoftBox>
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        <ErrorMessage name={nhomHoatDong.name} />
                      </SoftTypography>
                    </SoftBox>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {!containsEdit && id ? (
                  <FormField
                    type={"text"}
                    label={"Ngày Bắt Đầu"}
                    name={"dob"}
                    value={formatDate(getData?.startTime)}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      mb={0.5}
                      mt={2.2}
                    >
                      Ngày Bắt Đầu
                    </SoftTypography>
                    <SoftDatePicker
                      value={startDate}
                      onChange={(e) => handleSetDob(e, ngayBatDau.name)}
                    />
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        <ErrorMessage name={ngayBatDau.name} />
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {!containsEdit && id ? (
                  <FormField
                    type={"text"}
                    label={"Ngày Kết Thúc"}
                    name={"dob"}
                    value={formatDate(getData?.endTime)}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      mb={0.5}
                      mt={2.2}
                    >
                      Ngày Kết Thúc
                    </SoftTypography>
                    <SoftDatePicker
                      value={endDate}
                      onChange={(e) => handleSetDob(e, ngayKetThuc.name)}
                    />
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        <ErrorMessage name={ngayKetThuc.name} />
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                {!containsEdit && id ? (
                  <FormField
                    type={"text"}
                    label={hanGuiMinhChung.label}
                    name={"dob"}
                    value={formatDate(getData?.deadLineToPost) ?? "--"}
                    disabled={id && !containsEdit}
                  />
                ) : (
                  <SoftBox display="flex" flexDirection="column">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      mb={0.5}
                      mt={2.2}
                    >
                      Hạn gửi minh chứng
                    </SoftTypography>
                    <SoftDatePicker
                      value={deadLineDate}
                      onChange={(e) => handleSetDob(e, hanGuiMinhChung.name)}
                    />
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        <ErrorMessage name={hanGuiMinhChung.name} />
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <SoftBox display="flex" flexDirection="column">
                  {id && !containsEdit ? (
                    <FormField
                      type={loaiHD.type}
                      label={loaiHD.label}
                      name={loaiHD.name}
                      value={getData?.type}
                      error={errors.loaiHD && touched.loaiHD}
                      disabled={id && !containsEdit}
                    />
                  ) : (
                    <>
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        mb={0.9}
                        mt={1.7}
                      >
                        {loaiHD.label}
                      </SoftTypography>
                      <SoftSelect
                        // isLoading={loading}
                        // onMenuOpen={handleFetchDrivers}
                        value={activityType}
                        // onChange={handleSelectActivityGroup}
                        onChange={(e) => {
                          setIsPayload((prev) => ({ ...prev, loaiHD: e.value ?? "" }));
                          setActivityType({ value: e.values, label: e.label });
                        }}
                        options={TypeActivityEnum?.map((items) => ({
                          label: items,
                          value: items,
                        }))}
                      />
                      <SoftBox mt={0.75}>
                        <SoftTypography component="div" variant="caption" color="error">
                          <ErrorMessage name={loaiHD.name} />
                        </SoftTypography>
                      </SoftBox>
                    </>
                  )}
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormField
                  type={ghiChu.type}
                  label={ghiChu.label}
                  name={ghiChu.name}
                  value={ghiChuV}
                  error={errors.ghiChu && touched.ghiChu}
                  disabled={id && !containsEdit}
                />
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FileAttachment
                  id={id}
                  containsEdit={containsEdit}
                  containsCreate={containsCreate}
                  containsConfirm={containsConfirm}
                  file={getData.files || []}
                  setFile={setFiles}
                />
              </Grid>
            </Grid>
          </SoftBox>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DataTable
            loading={loading}
            query={query}
            setQuery={setQuery}
            table={dataTable}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
            canSearch
          />
          {Object.keys(dataTable.rows).length === 0 && !loading && (
            <SoftBox my={7}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                Không có dữ liệu
              </Grid>
            </SoftBox>
          )}

          {Object.keys(isData).length <= 2 && <SoftBox my={5}></SoftBox>}
        </CustomTabPanel>
      </Box>
    </div>
  );
}

// typechecking props for UserInfo
ChiTietHoatDong.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ChiTietHoatDong;
